import Chunk from './Chunk'
import ReduxStore from '../state/store'
import {hideFullScreen} from '../state/uiSlice'

export default class ChunkManager {

    // We will need to keep enough chunks loaded to display the most zoomed-out version of our landscape.
    // This is computed based on the maximum size of the landscape we want to show through the viewport in
    // whichever dimension is the largest.
    static maxVisibility = 20000 // 20 km -- yields a maximum of 400 1km chunks
    static minVisibility = 10
    static chunksPerRow = ChunkManager.maxVisibility / Chunk.size
    chunkBag = new Map()

    // We don't actually create Chunk objects unless that chunk would contain some terrain.  Chunk coordinates
    // with no terrain are represented as null values in the visibleChunks array.
    lastVisibleRect = { x: -1, y: -1, width: 0, height: 0 }
    heightMapQueue = []
    shapeQueue = []
    currentLod = 5

    constructor(terrain) {
        this.terrain = terrain

        this.chunkIsVisible = this.chunkIsVisible.bind(this)
        this.loadChunks = this.loadChunks.bind(this)
        this.lodForScale = this.lodForScale.bind(this)
        this.pruneChunks = this.pruneChunks.bind(this)
        this.queueHeightMap = this.queueHeightMap.bind(this)
        this.queueShape = this.queueShape.bind(this)
        this.receiveHeightMap = this.receiveHeightMap.bind(this)
        this.receiveShape = this.receiveShape.bind(this)
        this.rectsCollide = this.rectsCollide.bind(this)
        this.schedulePruning = this.schedulePruning.bind(this)
        this.shouldLoadChunks = this.shouldLoadChunks.bind(this)

        this.heightMapWorker = new Worker(new URL('./ChunkMapWorker.js', import.meta.url))
        this.heightMapWorker.onmessage = this.receiveHeightMap
        this.heightMapWorker.onerror = (error) => console.error(`heightMapWorker error: ${JSON.stringify(error)}`)
        const regionData = JSON.stringify(global.world.currentRegion())
        this.heightMapWorker.postMessage(regionData)

        this.shapeWorker = new Worker(new URL('./ChunkShapeWorker.js', import.meta.url))
        this.shapeWorker.onmessage = this.receiveShape
        this.shapeWorker.onerror = (error) => console.error(`shapeWorker error: ${JSON.stringify(error)}`)
        const regionEllipses = JSON.stringify(global.world.currentRegion().archipelagos.map( arch => arch.ellipses ).flat())
        this.shapeWorker.postMessage(["region", 1000, regionEllipses])
    }

    loadChunks(visibleRect) {
        if (!this.shouldLoadChunks(visibleRect)) return
        this.currentLod = this.lodForScale(visibleRect)
        const startX = Math.floor(visibleRect.x / Chunk.size)
        const startY = Math.floor(visibleRect.y / Chunk.size)
        const endX = Math.floor((visibleRect.x + visibleRect.width) / Chunk.size)
        const endY = Math.floor((visibleRect.y + visibleRect.height) / Chunk.size)
        // console.log(`* Making chunks centered around ${regionPos.x},${regionPos.y}`)
        // console.log(`** Top left is  ${startX},${startY}`)
        for (let y = startY; y <= endY; y++) {
            for (let x = startX; x <= endX; x++) {
                const targetChunkKey = Chunk.keyForChunk(x, y)
                const cachedChunk = this.chunkBag.get(targetChunkKey)
                if (cachedChunk === undefined) {
                    // We don't have info on this chunk.  Get its data from the worker
                    this.queueHeightMap(targetChunkKey)
                } else {
                    // We found this chunk location in the bag.  It might be a real chunk or it might be null.
                    // Note that if it is in the bag it is already in the terrain.
                    if (cachedChunk && cachedChunk.lod !== this.currentLod) {
                        this.queueHeightMap(targetChunkKey)
                    }
                }
            }
        }
        this.schedulePruning(visibleRect)
    }

    destroy() {
        this.terrain = null
        this.heightMapWorker.terminate()
        this.heightMapWorker.onmessage = null
        this.heightMapWorker.onerror = null
        this.heightMapQueue = []
        this.chunkBag.forEach( (chunk, key) => {
            if (chunk) {
                chunk.destroy()
            }
        })
        this.chunkBag.clear()
    }

    queueHeightMap(targetChunkKey) {
        if (this.heightMapQueue.includes(targetChunkKey)) return
        const waitingFor = this.heightMapQueue.length
        //console.log(`queuing chunk: ${targetChunkKey}`)
        this.heightMapQueue.push(targetChunkKey)
        if (waitingFor === 0)
            this.heightMapWorker.postMessage([targetChunkKey, this.currentLod])
    }

    queueShape(targetChunkKey, lod, shapes) {
        if (this.shapeQueue.some( entry => (entry[0] === targetChunkKey) && (entry[1] === lod) )) return
        const waitingFor = this.shapeQueue.length
        //console.log(`queuing shape: ${targetChunkKey}, ${lod}`)
        this.shapeQueue.push([targetChunkKey, lod, shapes])
        if (waitingFor === 0)
            this.shapeWorker.postMessage(this.shapeQueue[0], [this.shapeQueue[0][2].buffer])
    }

    receiveHeightMap(message) {
        const { x, y, map, lod } = message.data
        //console.log(`Received height map for ${x},${y}`)
        const region = global.world.currentRegion()
        const targetChunkKey = Chunk.keyForChunk(x, y)
        const requested = this.heightMapQueue.shift()
        if (!requested) return // The queue was cleared
        let chunk = null
        if (map !== null) {
            // Create the chunk
            chunk = new Chunk(x * Chunk.size, y * Chunk.size, region, map, lod)
            const shapes = chunk.draw()
            this.queueShape(targetChunkKey, lod, shapes)
            this.terrain.addChunk(chunk)
        }
        const oldChunk = this.chunkBag.get(targetChunkKey)
        if (oldChunk) {
            this.terrain.removeChunk(oldChunk)
            oldChunk.destroy()
        }
        this.chunkBag.set(targetChunkKey, chunk)
        if (this.heightMapQueue.length > 0) {
            this.heightMapWorker.postMessage([this.heightMapQueue[0], this.currentLod])
        }
    }

    receiveShape(message) {
        const { chunkKey, polygons, /* lod */} = message.data
        if (chunkKey === "region") {
            //console.log(`Received region polygons (${polygons.length}):`)
            //polygons.forEach( poly => console.log(`*** ${JSON.stringify(poly)}`))
            global.world.currentRegion().landPolygons = polygons
            global.pixiApp.regionMap.draw()
            ReduxStore.dispatch(hideFullScreen())
            return
        }
        //console.log(`Received shapes for ${chunkKey} ${lod}: ${polygons}`)
        const requested = this.shapeQueue.shift()
        if (!requested) return // The queue was cleared
        const chunk = this.chunkBag.get(chunkKey)
        if (chunk)
            chunk.setPolygons(polygons)
        if (this.shapeQueue.length > 0) {
            this.shapeWorker.postMessage(this.shapeQueue[0], [this.shapeQueue[0][2].buffer])
        }
    }

    schedulePruning(visibleRect) {
        if (this.pruneTask) { clearTimeout(this.pruneTask) }
        this.pruneTask = setTimeout(() => this.pruneChunks(visibleRect), 500)
    }

    pruneChunks(visibleRect) {
        let pruned = 0
        //console.log(`visibleRect => ${JSON.stringify(visibleRect)}`)
        this.chunkBag.forEach( (chunk, key) => {
            // Note that we never get rid of null chunk lookups, they are cheap to hold on to
            if (chunk && !this.chunkIsVisible(chunk.x, chunk.y, visibleRect)) {
                pruned++
                //console.log(`pruning ${chunk.x},${chunk.y}`)
                this.chunkBag.delete(key)
                if (chunk) {
                    this.terrain.removeChunk(chunk)
                    chunk.destroy()
                }
            }
        })
        this.pruneTask = 0
        console.log(`pruned ${pruned}  chunkBag => ${this.chunkBag.size}`)
    }

    shouldLoadChunks(visibleRect) {
        if (this.lastVisibleRect.x !== visibleRect.x || this.lastVisibleRect.y !== visibleRect.y ||
            this.lastVisibleRect.width !== visibleRect.width || this.lastVisibleRect.height !== visibleRect.height) {
            this.lastVisibleRect = visibleRect
            return true
        }
        return false
    }

    /**
     * Is the given chunk visible in the viewport?
     * @param x -- the region x coordinate of this chunk
     * @param y -- the region y coordinage of this chunk
     * @param visibleRect -- the currently visible part of the region, in region coords
     * @returns {boolean}
     */
    chunkIsVisible(x, y, visibleRect) {
        const box1 = [x, y, x + Chunk.size, y + Chunk.size]
        const box2 = [visibleRect.x, visibleRect.y, visibleRect.x + visibleRect.width, visibleRect.y + visibleRect.height]
        return this.rectsCollide(box1, box2)
    }

    rectsCollide(box1, box2) {
        return !(box1[2] < box2[0] || box2[2] < box1[0] || box1[3] < box2[1] || box2[3] < box1[1])
    }

    lodForScale(visibleRect) {
        const maxVisible = visibleRect.width > visibleRect.height ? visibleRect.width : visibleRect.height
        if (maxVisible < 500) {
            return 160
        } else if (maxVisible < 1000) {
            return 80
        } else if (maxVisible < 2500) {
            return 40
        } else if (maxVisible < 5000) {
            return 20
        } else if (maxVisible < 10000) {
            return 10
        } else {
            return 5
        }
    }
}
