import { useDispatch } from 'react-redux'
import { toggleSideBar} from '../state/uiSlice'
import './TopButtons.css'

export default function MenuButton() {

    const dispatch = useDispatch()

    function toggleMenu() {
        dispatch(toggleSideBar())
    }

    return <div className="circleButton menuButton" onClick={toggleMenu}>
        <div>
            <span className="material-symbols-outlined buttonIcon">menu</span>
        </div>
    </div>
}
