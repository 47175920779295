import { DateTime } from 'luxon'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'
import { useDispatch } from 'react-redux'
import {hideLoadModal, hideSideBar, navigateFullScreenTo, showFullScreen} from '../state/uiSlice'
import World from '../world/World'

export default function LoadGameModal(props) {

    const dispatch = useDispatch()
    const [ activeSlot, setActiveSlot ] = useState('')

    function loadGame() {

        if (!activeSlot) return

        dispatch(hideLoadModal())
        dispatch(hideSideBar())
        World.loadSavedGame(activeSlot)
        dispatch(showFullScreen())
        dispatch(navigateFullScreenTo("loading"))
    }

    function handleClose() {
        dispatch(hideLoadModal())
    }

    function nameForSlot(slot) {
        const time = parseInt(slot.substring(5))
        const date = DateTime.fromMillis(time)
        return date.toFormat('yyyy-LL-dd HH:mm:ss')
    }

    const entries = Object.entries(localStorage).map( entry => entry[0] )
    const saveSlots = entries.filter( entry => entry.startsWith('save-') )
    const slotList = saveSlots.map( slot => {
        const otherProps = {}
        if (slot === activeSlot) { otherProps.active = true }
        return <ListGroup.Item action {...otherProps} key={ slot } className="sideBarItem" onClick={ () => setActiveSlot(slot) }>{ nameForSlot(slot) }</ListGroup.Item>
    })

    return <Modal show={props.show} onHide={ handleClose }>
        <Modal.Header>
            <Modal.Title>Load Game</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <ListGroup>
                { slotList }
            </ListGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={ handleClose }>Cancel</Button>
            <Button variant="primary" onClick={ loadGame }>Load</Button>
        </Modal.Footer>
    </Modal>
}
