import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { hideSideBar } from '../state/uiSlice'

export default function PiratesApp() {

    const [pixiView, setPixiView] = useState(global.pixiApp.app.view)
    const dispatch = useDispatch()
    const ref = useRef(null)

    useEffect(() => {
        // Add app to DOM
        global.setReactPixiView = (newPixiView) => setPixiView(newPixiView)
        ref.current.appendChild(pixiView)
        return () => {}
    }, [pixiView])

    function closeMenus() {
        dispatch(hideSideBar())
    }

    return <div ref={ref} onClick={ closeMenus } />
}
