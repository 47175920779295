import { useDispatch } from 'react-redux'
import { navigateFullScreenTo, showLoadModal } from '../state/uiSlice'
import background from '../images/island2.png'
import Button from 'react-bootstrap/Button'
import World from '../world/World'
import './StartScreen.css'

export default function StartScreen() {

    const dispatch = useDispatch()

    function startGame() {
        dispatch(navigateFullScreenTo("createCaptain"))
    }

    function loadGame() {
        dispatch(showLoadModal())
    }

    function continueGame() {
        World.loadSavedGame('autosave')
        dispatch(navigateFullScreenTo("loading"))
    }

    const autoSaveData = localStorage.getItem('autosave')
    const entries = Object.entries(localStorage).map( entry => entry[0] )
    const saveSlots = entries.filter( entry => entry.startsWith('save-') )

    return <div className="startScreen" style={{ backgroundImage: `url(${background})` }}>
        <div style={{ marginBottom: "20px" }}/>
        <div className="startContent">
            <h1>Pelago</h1>
            <Button className="mainButton" onClick={startGame}>New Game</Button>
            { saveSlots.length > 0 ? <Button className="mainButton" onClick={loadGame}>Load Game</Button> : null }
            { autoSaveData ? <Button className="mainButton" onClick={continueGame}>Continue</Button> : null }
        </div>
    </div>
}
