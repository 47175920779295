import { Graphics } from 'pixi.js'

export default class PersonGraphic extends Graphics {
    constructor(headColor, bodyColor) {
        super()

        this.headColor = headColor
        this.bodyColor = bodyColor

        this.draw = this.draw.bind(this)

        this.draw()
    }

    draw() {
        this.lineStyle(0.05, 0x0)
        this.beginFill(this.bodyColor)
        this.drawRect(-0.3, -0.1, 0.6, 0.2)
        this.endFill()
        this.beginFill(this.headColor)
        this.drawCircle(0, -0.025, 0.12)
    }
}
