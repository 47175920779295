import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useDispatch} from 'react-redux'
import {showLoadModal, hideSaveModal} from '../state/uiSlice'

export default function SaveGameModal(props) {

    const dispatch = useDispatch()

    function handleClose() {
        dispatch(hideSaveModal())
    }

    function moveOn() {
        dispatch(hideSaveModal())
        dispatch(showLoadModal())
    }

    function save() {
        global.pixiApp.saveGame()
        moveOn()
    }

    return <Modal show={props.show} onHide={ handleClose }>
        <Modal.Header>
            <Modal.Title>Save current game?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Do you want to save this game before loading another?
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancel</Button>
            <Button variant="danger" onClick={moveOn}>Don't Save</Button>
            <Button variant="primary" onClick={save}>Save</Button>
        </Modal.Footer>
    </Modal>
}
