import { Graphics } from 'pixi.js'
import PersonGraphic from './PersonGraphic'
import VectorIndicator from './VectorIndicator'
import Intersects from 'yy-intersects'
import Terrain from './Terrain'

export default class BoatGraphic extends Graphics {
    constructor() {
        super()
        this.checkCollisions = this.checkCollisions.bind(this)
        this.colliders = this.colliders.bind(this)
        this.draw = this.draw.bind(this)
        this.destroy = this.destroy.bind(this)
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.tick = this.tick.bind(this)
    }

    init(boat) {
        this.boat = boat
        this.mainSail = new Graphics()
        this.jib = new Graphics()

        this.driver = new PersonGraphic(0xffd88f, 0xff3333)

        // Debug vectors
        this.sailLiftVector = new VectorIndicator(0x0000ff)
        this.sailDragVector = new VectorIndicator(0xff0000)
        this.keelForceVector = new VectorIndicator(0x00ffff)
        this.movementVector = new VectorIndicator(0x00ff00)
        this.movementVector.scale.x = 0.2
        this.apparentWindVector = new VectorIndicator(0x000000)

        this.sailAngle = 0
        this.direction = 1
        this.period = 640 // frames
        this.rate = this.maxSailAngle / this.period

        this.draw()

        return this
    }

    checkCollisions() {
        let hitTerrain = false
        this.collisionPolygons.forEach( polygon => polygon.update() )
        this.parent.children.forEach( sibling => {
            if (sibling.colliders && sibling !== this) {
                sibling.colliders().forEach( otherCollider => {
                    this.colliders().forEach( myCollider => {
                        if (otherCollider.collides(myCollider)) {
                            this.boat.handleCollision(sibling, otherCollider)
                            if (sibling instanceof Terrain)
                                hitTerrain = true
                        }
                    })
                })
            }
        })
        this.boat.aground = hitTerrain
    }

    colliders() {
        return this.collisionPolygons
    }

    draw() {
        this.lineStyle(0.2, 0x0)
        this.beginFill(0x78581c)
        const shape = [-1.5, 5, 1.5, 5, 2, -1, 0, -5, -2, -1]
        this.drawPolygon(shape)
        this.collisionPolygons = [new Intersects.Polygon(this, shape)]
        this.endFill()
        this.lineStyle(0.05, 0x0)
        this.drawCircle(0, -1, 0.2)

        this.mainSail.lineStyle(0.05, 0x0)
        this.mainSail.beginFill(0xffffff)
        this.mainSail.drawRect(-0.15, 0, 0.3, 5.5)
        this.mainSail.endFill()
        this.mainSail.pivot.set(0, -0.2)
        this.mainSail.position.set(0, -1)

        this.jib.lineStyle(0.05, 0x0)
        this.jib.beginFill(0xffffff)
        this.jib.drawRect(-0.1, 0, 0.2, 3.7)
        this.jib.endFill()
        this.jib.pivot.set(0, 0)
        this.jib.position.set(0, -5)

        this.driver.position.set(0, 3)

        this.addChild(this.driver)
        this.addChild(this.mainSail)
        this.addChild(this.jib)

        this.addChild(this.sailLiftVector)
        this.addChild(this.sailDragVector)
        this.addChild(this.keelForceVector)
        this.addChild(this.movementVector)
        this.addChild(this.apparentWindVector)
    }

    destroy(options) {
        this.stop()
        this.driver.destroy()
        this.driver = null
        this.mainSail.destroy()
        this.mainSail = null
        this.jib.destroy()
        this.jib = null
        super.destroy(options)
    }

    start() {
        global.pixiApp.app.ticker.add(this.tick)
    }

    stop() {
        global.pixiApp.app.ticker.remove(this.tick)
    }

    tick(/* delta */) {
        this.mainSail.rotation = this.boat.sailAngle
        this.jib.rotation = this.boat.sailAngle
        this.rotation = this.boat.heading
        this.position.set(this.boat.position.x, this.boat.position.y)

        const scale = -1
        this.sailLiftVector.rotation = this.boat.sailLift.angle
        this.sailLiftVector.scale.y = this.boat.sailLift.magnitude * scale
        this.sailDragVector.rotation = this.boat.sailDrag.angle
        this.sailDragVector.scale.y = this.boat.sailDrag.magnitude * scale
        this.keelForceVector.rotation = this.boat.keelForce.angle
        this.keelForceVector.scale.y = this.boat.keelForce.magnitude * scale
        this.movementVector.rotation = this.boat.movement.angle
        this.movementVector.scale.y = this.boat.movement.magnitude * scale
        this.apparentWindVector.rotation = this.boat.apparentWind.angle
        this.apparentWindVector.scale.y = this.boat.apparentWind.magnitude * scale

        this.checkCollisions()
    }
}
