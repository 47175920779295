/**
 * Is the given point within the circle defined by the center and radius?
 * @param point
 * @param center
 * @param radius
 */
export function isWithinRadius(point, center, radius) {
    const difference = subtractVector(point, center)
    return Math.sqrt(Math.pow(difference.x, 2) + Math.pow(difference.y, 2)) < radius
}

export function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max)
}

/**
 * What is the angle from the start point to the dest point, in radions, where 0 radians points up.
 * In our caordinate system y gets bigger as you go down the screen.
 * @param start -- The starting point coordinates
 * @param dest -- The ending point coordinates
 * @returns {number} -- The angle in radians
 */
export function angleTo(start, dest) {
    if (dest.x - start.x === 0) return (dest.y - start.y) < 0 ? 0 : Math.PI
    const angle = Math.atan((dest.y - start.y) / (dest.x - start.x))
    if (dest.x >= start.x && dest.y < start.y) {
        // 1st quadrant
        // angle will be negative
        return (Math.PI / 2) + angle
    } else if (dest.x < start.x && dest.y < start.y) {
        // 2nd quadrant
        // angle will be positive
        return (3 * Math.PI / 2) + angle
    } else if (dest.x < start.x && dest.y >= start.y) {
        // 3rd quadrant
        // angle will be negative
        return (3 * Math.PI / 2) + angle
    } else {
        // 4th quadrant
        // angle will be positive
        return (Math.PI / 2) + angle
    }
}

/**
 * What is the minimum number of radians we have to travel on a circle to get from angle1 to angle2?
 * @param angle1
 * @param angle2
 * @returns {number} positive == clockwise, negative == counterclockwise
 */
export function angleDiff(angle1, angle2) {
    if (angle1 === angle2) return 0
    const portAngle = angleToPort(angle1, angle2)
    const starboardAngle = angleToStarboard(angle1, angle2)
    if (portAngle < starboardAngle)
        return -portAngle
    else
        return starboardAngle
}

/**
 * How many radians do we have to turn in a clockwise direction to get from angle1 to angle2?
 * @param angle1
 * @param angle2
 * @returns {number}
 */
export function angleToStarboard(angle1, angle2) {
    if (angle2 < angle1) angle2 += Math.PI * 2
    return angle2 - angle1
}

/**
 * How many radians do we have to turn in a counter-clockwise direction to get from angle1 to angle2?
 * @param angle1
 * @param angle2
 * @returns {number}
 */
export function angleToPort(angle1, angle2) {
    if (angle1 < angle2) angle1 += Math.PI * 2
    return angle1 - angle2
}

export function normalizeAngle(angle) {
    while (angle < 0) angle += Math.PI * 2
    return angle % (Math.PI * 2)
}

export function splitAngle(angle) {
    angle = normalizeAngle(angle)
    return angle > Math.PI ? -(2 * Math.PI - angle) : angle
}

/**
 * Get the x and y components for a given magnitude at a given angle.
 * Note that our y axis is flipped (y gets bigger as you go down).
 * @param angle
 * @param magnitude
 * @returns {{x: number, y: number}}
 */
export function vectorFor(angle, magnitude) {
    const x = Math.sin(angle) * magnitude
    const y = Math.cos(angle) * -magnitude
    return { x, y }
}

/**
 * What is the force (aka length of the hypotenuse) and angle of a given vector?
 * @param vector
 * @returns {{angle: number, magnitude: number}}
 */
export function magnitudeAndAngleFor(vector) {
    if (vector.x === 0) return { angle: vector.y < 0 ? 0 : Math.PI, magnitude: Math.abs(vector.y) }
    const angleToXAxis = Math.atan(vector.y / vector.x)
    let angle
    if (vector.x >= 0 && vector.y < 0) {
        // 1st quadrant (upper right)
        // angleToXAxis will be negative
       angle = (Math.PI / 2) + angleToXAxis
    } else if (vector.x < 0 && vector.y < 0) {
        // 2nd quadrant (upper left)
        // angleToXAxis will be positive
        angle = (3 * Math.PI / 2) + angleToXAxis
    } else if (vector.x < 0 && vector.y >= 0) {
        // 3rd quadrant (lower left)
        // angleToXAxis will be negative
        angle = (3 * Math.PI / 2) + angleToXAxis
    } else {
        // 4th quadrant (lower right)
        // angleToXAxis will be positive
        angle = (Math.PI / 2) + angleToXAxis
    }
    const magnitude = Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2))
    return { angle, magnitude }
}

export function addVector(vector1, vector2) {
    return { x: vector1.x + vector2.x, y: vector1.y + vector2.y }
}

export function subtractVector(vector1, vector2) {
    return { x: vector1.x - vector2.x, y: vector1.y - vector2.y }
}

export function multiplyVectorByScalar(vector, scalar) {
    return { x: vector.x * scalar, y: vector.y * scalar }
}

export function addMagnitudeToVector(vector, magnitude) {
    const components = magnitudeAndAngleFor(vector)
    components.magnitude += magnitude
    return vectorFor(components.angle, components.magnitude)
}

export function maxMagnitude(vector, maxMagnitude) {
    const components = magnitudeAndAngleFor(vector)
    if (components.magnitude > maxMagnitude) {
        components.magnitude = maxMagnitude
        return vectorFor(components.angle, components.magnitude)
    } else {
        return vector
    }
}

export function rotateVector(vector, angle) {
    const x = vector.x * Math.cos(angle) - vector.y * Math.sin(angle)
    const y = vector.y * Math.cos(angle) + vector.x * Math.sin(angle)
    return { x, y }
}
