import './TopButtons.css'

export default function PauseButton() {

    function pauseTimeScale() {
        global.world.pauseTimeScale()
    }

    return <div className="circleButton pauseButton" onClick={pauseTimeScale}>
        <div>
            <span className="material-symbols-outlined buttonIcon">pause</span>
        </div>
    </div>
}
