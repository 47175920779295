
const colors = {
    deepWater: 0x0f6dfa,
    shallowWater: 0x9999cc,
    beach: 0xfeffba,
    jungle: 0x44cc44,
    hills: 0x0b420f,
    mountains: 0x000000,
    snow: 0xffffff,
    parchment: 0xf5f5dc,
    mapBackground: 0xebcfa8,
    mapLand: 0xcda672,
    mapOutline: 0x584234
}

export default colors
