import { useSelector } from 'react-redux'
import CreateCaptainScreen from '../screens/CreateCaptainScreen'
import StartScreen from '../screens/StartScreen'
import LoadingScreen from '../screens/LoadingScreen'

export default function FullScreenUi() {

    const currentScreen = useSelector( state => state.ui.currentScreen )

    let content
    switch (currentScreen) {
        case "createCaptain":
            content = <CreateCaptainScreen />
            break
        case "loading":
            content = <LoadingScreen />
            break
        default:
            content = <StartScreen />
    }

    return <div className="fullScreenUi">
        { content }
    </div>
}
