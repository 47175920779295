import './TopButtons.css'

export default function MapButton() {

    function toggleMap() {
        global.pixiApp.toggleMap()
    }

    return <div className="circleButton mapButton" onClick={toggleMap}>
        <div>
            <span className="material-symbols-outlined buttonIcon">map</span>
        </div>
    </div>
}
