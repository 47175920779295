import Alea from 'aleaprng'
import {DateTime, Duration} from 'luxon'
import SkillSet from './SkillSet'
import Strategies from './tasks/Strategies'
import {nanoid} from 'nanoid'

const rand = Alea()

export default class Person {

    constructor() {
        this.init = this.init.bind(this)
        this.age = this.age.bind(this)
        this.bestStrategyFor = this.bestStrategyFor.bind(this)
    }

    init() {
        this.id = nanoid()
        const fourteenYears = 1000 * 60 * 60 * 24 * 365 * 14
        const sixtyYears = 1000 * 60 * 60 * 24 * 365 * 60
        this.birthday = global.world.currentTime - rand.range(fourteenYears, sixtyYears)
        this.gender = Person.randomGender()
        this.attraction = Person.randomAttraction(this.gender)
        this.firstName = Person.randomFirstName(this.gender)
        this.lastName = Person.randomLastName()
        this.traits = Person.randomTraits()
        let traitCost = 0
        this.traits.forEach( trait => traitCost += trait.cost )
        // Between 3 and 9 points at random, plus half a point for each year of age, minus the cost of traits (which may be negative)
        const points = rand.range(3, 10) + Math.ceil(this.age() * 0.5) - traitCost
        this.skills = SkillSet.randomSkillset(this.age(), points)
        return this
    }

    loadFromSaveData(data) {
        Object.assign(this, data) // covers most things
        this.birthday = new DateTime(data.birthday)
        this.skills = new SkillSet().loadFromSaveData(data.skills)
        return this
    }

    age() {
        const endOfLifespan = this.deathday ? this.deathday : global.world.currentTime
        const lifeSpan = endOfLifespan - this.birthday
        const duration = Duration.fromMillis(lifeSpan)
        return Math.floor(duration.as('years'))
    }

    bestStrategyFor(task) {
        switch (task.name) {
            case 'helm':
                return Strategies.helm.straightToDestination
            case 'rigging':
                return Strategies.rigging.prettyGoodTrim
            default:
                return null
        }
    }

    static randomGender() {
        const roll = rand.range(9)
        if (roll < 5)
            return "male"
        if (roll < 8)
            return "female"
        return "non-binary"
    }

    static oppositeGender(gender) {
        if (gender === "male")
            return "female"
        if (gender === "female")
            return "male"
        return "non-binary";
    }

    static randomAttraction(gender) {
        if (gender !== "male" && gender !== "female") {
            if (rand.range(1)) gender = "male"
            else gender = "female"
        }
        const roll = rand.range(9)
        if (roll < 5)
            return Person.oppositeGender(gender)
        else if (roll < 7)
            return gender
        else
            return "anyone"
    }

    static randomFirstName(gender) {
        let firstNames
        if (gender === "male")
            firstNames = Person.firstNamesMale
        else if (gender === "female")
            firstNames = Person.firstNamesFemale
        else
            firstNames = rand.range(1) ? Person.firstNamesMale : Person.firstNamesFemale
        return firstNames[rand.range(firstNames.length - 1)]
    }

    static randomLastName() {
        return Person.lastNames[rand.range(Person.lastNames.length - 1)]
    }

    static randomTraits() {
        return []
        // const traits = []
        // let numTraits = 0
        // const roll = rand.range(10)
        // if (roll < 5)
        //     numTraits = 0
        // else if (roll < 7)
        //     numTraits = 1
        // else if (roll < 8)
        //     numTraits = 2
        // else
        //     numTraits = 3
        // for (let x = 0; x < numTraits; x++) {
        //     const trait = Trait.randomTrait()
        //     if (!traits.find(cand => cand.name === trait.name))
        //         traits.push(trait)
        // }
        // return traits
    }

    static firstNamesMale = [
        "Aaron",
        "Albert",
        "Alan",
        "Alfred",
        "Alphonse",
        "Anthony",
        "Antione",
        "Arthur",
        "Arturo",
        "Benjamin",
        "Dale",
        "Daniel",
        "Derek",
        "Eduardo",
        "Edward",
        "Eric",
        "Fred",
        "George",
        "Grover",
        "Harold",
        "Henry",
        "Jackson",
        "James",
        "Jim",
        "Johnathon",
        "Jonah",
        "Keith",
        "Larry",
        "Martin",
        "Marvin",
        "Melville",
        "Morgan",
        "Napoleon",
        "Paul",
        "Patrick",
        "Perry",
        "Phillip",
        "Reginald",
        "Renaldo",
        "Ricardo",
        "Richard",
        "Robert",
        "Roberto",
        "Ronald",
        "Samuel",
        "Scott",
        "Stephen",
        "Thomas",
        "Tiberius",
        "Timothy",
        "Trevor",
        "Victor",
        "William",
    ]

    static firstNamesFemale = [
        "Alice",
        "Amelia",
        "Angela",
        "Anne",
        "Barbara",
        "Beatrice",
        "Catarina",
        "Catherine",
        "Clarissa",
        "Dale",
        "David",
        "Dierdre",
        "Elizabeth",
        "Ellen",
        "Eloise",
        "Felicia",
        "Georgia",
        "Jane",
        "Jennifer",
        "Judy",
        "Juliet",
        "Kate",
        "Kathryn",
        "Kerry",
        "Louise",
        "Lynn",
        "Maria",
        "Mary",
        "Maya",
        "Millicent",
        "Nancy",
        "Ophelia",
        "Patricia",
        "Rhianna",
        "Sally",
        "Sarah",
        "Susan",
        "Thomasina",
        "Tina",
        "Vera",
        "Victoria",
        "Viola",
        "Violet",
        "Wendy",
    ]

    static lastNames = [
        "Alvarez",
        "Anderson",
        "Baker",
        "Beuler",
        "Benton",
        "Burford",
        "Calhoun",
        "Calloway",
        "Carter",
        "Clinton",
        "Dalton",
        "Davis",
        "Everett",
        "Finn",
        "Florida",
        "Ford",
        "Fox",
        "Garfield",
        "Gerard",
        "Graham",
        "Grant",
        "Harris",
        "Heston",
        "Hilton",
        "Jackson",
        "Jefferson",
        "Jenkins",
        "Johnson",
        "Jordan",
        "Lafayette",
        "Leary",
        "Lincoln",
        "Logan",
        "Marley",
        "Martin",
        "Menendez",
        "Miller",
        "Morgan",
        "Perkins",
        "Perry",
        "Proust",
        "Ramirez",
        "Rivera",
        "Roberts",
        "Rogers",
        "Savage",
        "Sears",
        "Smith",
        "Stanley",
        "Steele",
        "Talbot",
        "Taylor",
        "Tucker",
        "Ward",
        "Washington",
        "Wilson",
        "Wright",
    ]
}
