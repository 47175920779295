import {Polygon} from 'yy-intersects'
import {Point} from 'pixi.js'

export default class ConcavePolygon extends Polygon {
    collidesPolygon(polygon, isAABB) {
        if (isAABB) return super.collidesPolygon(polygon, isAABB)
        const vertices = polygon.vertices
        for (let i = 0; i < vertices.length; i += 2) {
            if (this.collidesPoint(new Point(vertices[i], vertices[i + 1])))
                return true
        }
        return false
    }
}
