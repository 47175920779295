import { Application } from 'pixi.js'
import { Viewport} from 'pixi-viewport'
import BoatGraphic from './BoatGraphic'
import Colors from './Colors'
import RegionMap from './RegionMap'
import Terrain from './Terrain'
import TextScroller from './TextScroller'
import Chunk from './Chunk'
import ChunkManager from './ChunkManager'
import DebugInfo from './DebugInfo'
import Region from '../world/Region'
import TownLayer from './TownLayer'
import WindIndicator from './WindIndicator'

export default class PixiApp {

    autosaveInterval = 30000 // 30 seconds

    constructor() {

        this.autoSave = this.autoSave.bind(this)
        this.toggleMap = this.toggleMap.bind(this)
        this.saveGame = this.saveGame.bind(this)
        this.log = this.log.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.onKeyUp = this.onKeyUp.bind(this)
        this.onRendererResize = this.onRendererResize.bind(this)
        this.onViewportClick = this.onViewportClick.bind(this)
        this.onViewportDragged = this.onViewportDragged.bind(this)
        this.onViewportMoved = this.onViewportMoved.bind(this)
        this.onViewportZoomed = this.onViewportZoomed.bind(this)
        this.onWindowResize = this.onWindowResize.bind(this)
        this.start = this.start.bind(this)
        this.startFollowingBoat = this.startFollowingBoat.bind(this)
        this.stop = this.stop.bind(this)
        this.stopFollowingBoat = this.stopFollowingBoat.bind(this)
        this.toggleFollowBoat = this.toggleFollowBoat.bind(this)
        this.toggleMap = this.toggleMap.bind(this)
    }

    start() {

        this.app = new Application({
            backgroundColor: Colors.deepWater,
            antialias: true
        })

        document.addEventListener('keydown', this.onKeyDown)
        document.addEventListener('keyup', this.onKeyUp)
        window.addEventListener('resize', this.onWindowResize) // fired when the window is resized
        this.app.renderer.on('resize', this.onRendererResize) // fired after the renderer resizes

        this.viewport = new Viewport({
            passiveWheel: false,
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
            worldWidth: Region.size,
            worldHeight: Region.size,
            interaction: this.app.renderer.plugins.interaction
        })
        this.viewport
            .drag()
            .pinch()
            .wheel()
            .decelerate()
        this.viewport.clamp({ direction: 'all'})
        const viewportMaxVisibility = Chunk.size * (ChunkManager.chunksPerRow - 2)
        this.viewport.clampZoom({
            maxWidth: viewportMaxVisibility,
            maxHeight: viewportMaxVisibility,
            minWidth: ChunkManager.minVisibility,
            minHeight: ChunkManager.minVisibility
        })
        const maxDimension = window.innerWidth > window.innerHeight ? window.innerWidth : window.innerHeight
        const initialScale = maxDimension / viewportMaxVisibility
        this.viewport.setZoom(initialScale)
        this.viewport.on('clicked', this.onViewportClick)
        this.viewport.on('drag-start', this.onViewportDragged)
        this.viewport.on('moved', this.onViewportMoved)
        this.viewport.on('moved-end', this.onViewportMoved)
        this.viewport.on('zoomed', this.onViewportZoomed)
        this.viewport.on('zoomed-end', this.onViewportZoomed)
        this.app.stage.addChild(this.viewport)
        this.viewport.moveCenter(Region.size / 2, Region.size / 2)

        this.terrain = new Terrain().init()
        this.viewport.addChild(this.terrain)

        this.townLayer = new TownLayer().init()
        this.viewport.addChild(this.townLayer)

        this.boats = global.world.boats.map( boat => new BoatGraphic().init(boat) )
        this.boats.forEach ( boat => {
            this.viewport.addChild(boat)
            boat.start()
        })

        this.windIndicator = new WindIndicator().init()
        this.windIndicator.start()
        this.windIndicator.position.set(30, 30)
        this.app.stage.addChild(this.windIndicator)

        this.regionMap = new RegionMap().init(global.world.currentRegion()) // starts hidden
        this.regionMap.updateVisibleAreaRect(this.viewport.getVisibleBounds())

        this.textScroller = new TextScroller().init()
        this.app.stage.addChild(this.textScroller)

        this.debugInfo = new DebugInfo().init()
        this.debugInfo.start()
        this.app.stage.addChild(this.debugInfo)

        this.chunkManager = new ChunkManager(this.terrain)

        this.onWindowResize()

        this.app.start()
        global.world.start()

        this.autoSave()
    }

    stop() {
        clearTimeout(this.autoSaveTask)

        this.app.stop()
        global.world.stop()

        document.removeEventListener('keydown', this.onKeyDown)
        document.removeEventListener('keyup', this.onKeyUp)
        window.removeEventListener('resize', this.onWindowResize)
        this.app.renderer.off('resize', this.onRendererResize)

        this.chunkManager.destroy()

        this.debugInfo.destroy()
        this.debugInfo = null

        this.textScroller.destroy()
        this.textScroller = null

        this.regionMap.destroy()
        this.regionMap = null

        this.windIndicator.destroy()
        this.windIndicator = null

        this.boats.forEach( boat => boat.destroy() )
        this.boats = null

        this.townLayer.destroy()
        this.townLayer = null

        this.terrain.destroy()
        this.terrain = null

        this.viewport.off('clicked', this.onViewportClick)
        this.viewport.off('drag-start', this.onViewportDragged)
        this.viewport.off('moved', this.onViewportMoved)
        this.viewport.off('moved-end', this.onViewportMoved)
        this.viewport.off('zoomed', this.onViewportZoomed)
        this.viewport.off('zoomed-end', this.onViewportZoomed)
        this.viewport.destroy()
        this.viewport = null

        this.app.destroy(true)
    }

    toggleFollowBoat() {
        if (!this.followingBoat) {
            this.startFollowingBoat()
        } else {
            this.stopFollowingBoat()
        }
    }

    startFollowingBoat() {
        this.viewport.animate({
            position: this.boats[0].position,
            time: 1000,
            callbackOnComplete: () => { this.viewport.animate({ scale: 30, time: 1000 })} })
        this.viewport.follow(this.boats[0], { speed: 10 })
        this.followingBoat = true
    }

    stopFollowingBoat() {
        this.viewport.plugins.remove('follow')
        this.followingBoat = false
    }

    setBoatDestination(coords) {
        console.log(`Setting boat destination to ${JSON.stringify(coords)}`)
        this.boats[0].boat.destination = coords
    }

    teleportBoat(coords) {
        console.log(`Teleporting boat to ${coords.x},${coords.y}`)
        this.boats[0].boat.position = coords
    }

    toggleMap() {
        if (this.app.stage.children.includes(this.regionMap))
            this.app.stage.removeChild(this.regionMap)
        else
            this.app.stage.addChild(this.regionMap)
    }

    autoSave() {
        clearTimeout(this.autoSaveTask)
        const data = JSON.stringify(global.world)
        const key = 'autosave'
        localStorage.setItem(key, data)
        this.autoSaveTask = setTimeout(this.autoSave, this.autosaveInterval)
    }

    saveGame() {
        const data = JSON.stringify(global.world)
        const key = `save-${Date.now()}`
        localStorage.setItem(key, data)
        this.log('Save completed.')
    }

    log(message) {
        this.textScroller.addText(message)
    }

    onKeyDown(event) {
        //console.log(`${event.code} (aka ${event.key}) down`)
        const viewportMoveDistance = 100 / this.viewport.scaled
        switch (event.code) {
            case 'KeyB':
                this.toggleFollowBoat()
                return
            case 'KeyM':
                this.toggleMap()
                return
            case 'KeyT':
                console.log('about to teleport boat')
                this.teleportingBoat = true
                return

            case 'KeyZ':
                global.world.boats[0].heading -= Math.PI / 32
                return
            case 'KeyX':
                global.world.boats[0].heading += Math.PI / 32
                return
            case 'KeyA':
                global.world.boats[0].trimAngle -= Math.PI / 32
                return
            case 'KeyS':
                global.world.boats[0].trimAngle += Math.PI / 32
                return

            case 'ArrowUp':
                this.stopFollowingBoat()
                this.viewport.animate({
                    time: 200,
                    position: {x: this.viewport.center.x, y: this.viewport.center.y - viewportMoveDistance}
                })
                return
            case 'ArrowDown':
                this.stopFollowingBoat()
                this.viewport.animate({
                    time: 200,
                    position: {x: this.viewport.center.x, y: this.viewport.center.y + viewportMoveDistance}
                })
                return
            case 'ArrowLeft':
                this.stopFollowingBoat()
                this.viewport.animate({
                    time: 200,
                    position: {x: this.viewport.center.x - viewportMoveDistance, y: this.viewport.center.y}
                })
                return
            case 'ArrowRight':
                this.stopFollowingBoat()
                this.viewport.animate({
                    time: 200,
                    position: {x: this.viewport.center.x + viewportMoveDistance, y: this.viewport.center.y}
                })
                return
            case 'Equal':
                this.viewport.animate({
                    time: 200,
                    scale: this.viewport.scaled * 1.1
                })
                return
            case 'Minus':
                this.viewport.animate({
                    time: 200,
                    scale: this.viewport.scaled * 0.9
                })
                return
            case 'ShiftLeft':
            case 'ShirtRight':
                this.shiftDown = true
                return
            default:
        }
    }

    onKeyUp(event) {
        if (event.isComposing || event.keyCode === 229) return
        //console.log(`${event.code} (aka ${event.key}) up`)
        switch (event.code) {
            case 'Shift':
                this.shiftDown = false
                return
            default:
        }
    }

    onRendererResize(width, height) {
        console.log(`rederer is now ${width}x${height}`)
        this.regionMap.onResize(width, height)
        this.textScroller.onResize(width, height)
        this.chunkManager.loadChunks(this.viewport.getVisibleBounds())
        this.debugInfo.position.set(3, height - 113)
    }

    onWindowResize() {
        this.viewport.resize()
        const maxWidth = window.innerWidth
        const maxHeight = window.innerHeight
        this.app.renderer.resize(maxWidth, maxHeight)
    }

    onViewportClick(event) {
        if (this.shiftDown) {
            this.setBoatDestination(event.world)
        } else if (this.teleportingBoat) {
            this.teleportingBoat = false
            this.teleportBoat(event.world)
        }
    }

    onViewportDragged() {
        if (this.followingBoat) {
            this.stopFollowingBoat()
        }
    }

    onViewportMoved() {
        const visibleBounds = this.viewport.getVisibleBounds()
        global.world.regionPosition.x = visibleBounds.x + visibleBounds.width / 2
        global.world.regionPosition.y = visibleBounds.y + visibleBounds.height / 2
        this.chunkManager.loadChunks(visibleBounds)
        this.regionMap.updateVisibleAreaRect(visibleBounds)
    }

    onViewportZoomed() {
        const visibleBounds = this.viewport.getVisibleBounds()
        this.chunkManager.loadChunks(visibleBounds)
        this.regionMap.updateVisibleAreaRect(visibleBounds)
    }
}
