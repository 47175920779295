import {Graphics} from 'pixi.js'

export default class VectorIndicator extends Graphics {

    constructor(color) {
        super()
        this.color = color
        this.draw()
    }

    draw() {
        this.lineStyle(0, 0x0)
        this.beginFill(this.color)
        this.drawRect(-0.125, 0, 0.25, 1)
        this.endFill()
    }
}
