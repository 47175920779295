import { useDispatch } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import {
    hideSideBar,
    showFullScreen,
    navigateFullScreenTo,
    showSaveModal
} from '../state/uiSlice'
import './SideBar.css'

export default function SideBar(props) {

    const dispatch = useDispatch()

    function saveGame() {
        global.pixiApp.saveGame()
        dispatch(hideSideBar())
    }

    function loadGame() {
        dispatch(hideSideBar())
        dispatch(showSaveModal())
    }

    function quit() {
        global.pixiApp.autoSave()
        global.world.stop()
        global.pixiApp.stop()
        global.pixiApp = null
        global.world = null
        dispatch(hideSideBar())
        dispatch(showFullScreen())
        dispatch(navigateFullScreenTo('start'))
    }

    const position = props.open ? "0" : "-250px"

    return <div className="sideBar" style={{ right: position }}>
        <div className="sideBarHeader">
            <h1>Pelago</h1>
        </div>
        <ListGroup>
            <ListGroup.Item className="sideBarItem" onClick={saveGame}>Save Game</ListGroup.Item>
            <ListGroup.Item className="sideBarItem" onClick={loadGame}>Load Game</ListGroup.Item>
            <ListGroup.Item className="sideBarItem" onClick={quit}>Quit</ListGroup.Item>
        </ListGroup>
    </div>
}
