import {angleTo, angleToPort, angleToStarboard, isWithinRadius} from '../../utils/MathUtils'
import Strategy from './Strategy'

export class NoSteering extends Strategy {}

export class StraightToDestination extends Strategy {
    execute(delta, boat, person) {
        // TODO: This should be handled by the trimmer
        if (isWithinRadius(boat.position, boat.destination, 10)) {
            boat.speed = 0
        } else {
            boat.speed = 5
        }
        if (boat.speed === 0) return

        const destHeading = angleTo(boat.position, boat.destination)
        // Which rudder angle gets us to the destHeading faster?
        const portDiff = angleToPort(boat.heading, destHeading)
        const starboardDiff = angleToStarboard(boat.heading, destHeading)
        if (portDiff < starboardDiff) {
            // We should be turning to port
            boat.rudderAngle = Math.max(-boat.maxRudderAngle, -portDiff)
        } else {
            // We should be turning to starboard
            boat.rudderAngle = Math.min(boat.maxRudderAngle, starboardDiff)
        }
    }
}
