import {NoSteering, StraightToDestination} from './Helm'
import {LetSailsFlap, PrettyGoodTrim} from './Rigging'

export default class Strategies {
    static helm = {
        noSteering: new NoSteering(),
        straightToDestination: new StraightToDestination()
    }
    static rigging = {
        letSailsFlap: new LetSailsFlap(),
        prettyGoodTrim: new PrettyGoodTrim()
    }
}
