import Alea from 'aleaprng'
import { nanoid } from 'nanoid'

const rand = Alea(nanoid())

export default class Ellipse {
    constructor(x, y, a, b, angle) {
        this.x = x
        this.y = y
        this.a = a
        this.b = b
        this.angle = angle

        this.computeAabb = this.computeAabb.bind(this)
        this.containsPoint = this.containsPoint.bind(this)
        this.loadFromSaveData = this.loadFromSaveData.bind(this)
        this.randomEdgePoint = this.randomEdgePoint.bind(this)
        this.toString = this.toString.bind(this)

        this.computeAabb()
    }

    loadFromSaveData(data) {
        Object.assign(this, data)
        return this
    }

    computeAabb() {
        const rotation = this.angle
        const sin2 = Math.pow(Math.sin(rotation), 2)
        const cos2 = Math.pow(Math.cos(rotation), 2)
        const a2 = Math.pow(this.a, 2)
        const b2 = Math.pow(this.b, 2)
        const xa = Math.sqrt(a2 * cos2 + b2 * sin2)
        const ya = Math.sqrt(a2 * sin2 + b2  * cos2)
        this.aabb = [this.x - xa, this.y - ya, this.x + xa, this.y + ya]
    }

    aabbCollides(AABB) {
        const AABB2 = this.aabb
        return !(AABB[2] < AABB2[0] || AABB2[2] < AABB[0] || AABB[3] < AABB2[1] || AABB2[3] < AABB[1])
    }

    containsPoint(x, y, scale = 1.0) {
        return Ellipse.pointIsInside(x, y, this.x, this.y, this.a * scale, this.b * scale, this.angle)
    }

    randomEdgePoint() {
        const angle = rand.range(Math.PI * 2)
        return Ellipse.pointOnEdge(this.x, this.y, this.a, this.b, angle, this.angle)
    }

    static pointIsInside(x, y, centerX, centerY, a, b, angle) {
        return Math.pow(Math.cos(angle) * (x - centerX) + Math.sin(angle) * (y - centerY), 2) / Math.pow(a, 2) +
            Math.pow(Math.sin(angle) * (x - centerX) - Math.cos(angle) * (y - centerY), 2) / Math.pow(b, 2)
            < 1.0;
    }

    static pointOnEdge(centerX, centerY, a, b, angle, rotation) {
        // angle == the angle from the center to point on the unrotated ellipse
        // rotation == the angle the entire ellipse is rotated about the center
        const unrotatedX = a * Math.cos(angle)
        const unrotatedY = b * Math.sin(angle)
        const rotatedX = unrotatedX * Math.cos(rotation) - unrotatedY * Math.sin(rotation)
        const rotatedY = unrotatedY * Math.cos(rotation) + unrotatedX * Math.sin(rotation)
        return {
            x: centerX + rotatedX,
            y: centerY + rotatedY
        }
    }

    toString() {
        return `Ellipse ${this.x},${this.y} a: ${this.a} b: ${this.b} angle: ${this.angle}`
    }
}
