import { useSelector } from 'react-redux'
import FullScreenUi from './components/FullScreenUi'
import SaveGameModal from './components/SaveGameModal'
import LoadGameModal from './components/LoadGameModal'
import MapButton from './components/MapButton'
import MenuButton from './components/MenuButton'
import PiratesApp from './components/PiratesApp'
import SideBar from './components/SideBar'
import BoatButton from './components/BoatButton'
import SpeedUpTimeScaleButton from './components/SpeedUpTimeScaleButton'
import CancelTimeScaleButton from './components/CancelTimeScaleButton'
import PauseButton from './components/PauseButton'
import './App.css'

function App() {

    const fullScreenUiVisible = useSelector( state => state.ui.fullscreenUiVisible )
    const sideBarOpen = useSelector( state => state.ui.sideBarOpen )
    const loadModalShown = useSelector( state => state.ui.loadModalShown )
    const saveModalShown = useSelector( state => state.ui.saveModalShown )
    return <div className="appContainer">
        <div className="pixiWindow">
            { global.pixiApp ? <PiratesApp /> : <div className="pixiPlaceholder">World not loaded</div> }
        </div>
        <MapButton />
        <MenuButton />
        <BoatButton />
        <SpeedUpTimeScaleButton />
        <CancelTimeScaleButton />
        <PauseButton />
        <SideBar open={sideBarOpen} />
        { fullScreenUiVisible ? <FullScreenUi /> : null }
        <LoadGameModal show={loadModalShown} />
        <SaveGameModal show={saveModalShown} />
    </div>
}

export default App
