
export default class Strategy {
    skillsRequired = []

    constructor() {
        this.execute = this.execute.bind(this)
    }

    execute() { /* Nothing happens */ }
}
