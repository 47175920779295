import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { navigateFullScreenTo } from '../state/uiSlice'
import Button from 'react-bootstrap/Button'
import Person from '../people/Person'
import PersonDetails from '../components/PersonDetails'
import './CreateCaptainScreen.css'
import World from '../world/World'
import PixiApp from '../pixi/PixiApp'

export default function CreateCaptainScreen() {

    if (!global.world) {
        global.world = new World().init()
        global.world.createStartRegion()
    }

    const [person, setPerson] = useState(new Person().init())

    const dispatch = useDispatch()

    function reRoll() {
        setPerson(new Person().init())
    }

    function moveOn() {
        global.world.boats[0].crew = [ person.id ]
        global.world.boats[0].assignPersonTo(person, 'helm')
        global.world.boats[0].assignPersonTo(person, 'rigging')
        global.world.people = [ person ]
        global.pixiApp = new PixiApp()
        global.pixiApp.start()

        dispatch(navigateFullScreenTo("loading"))
    }

    return <div className="createCaptainScreen">
        <h2>Choose your Captain</h2>
        <div className="captainContainer">
            <PersonDetails person={person} />
        </div>
        <div className="bottomButtons">
            <Button className="mainButton" onClick={ reRoll }>Try Again</Button>
            <Button className="mainButton" onClick={ moveOn }>Continue</Button>
        </div>
    </div>
}
