import background from '../images/island2.png'
import './StartScreen.css'
import {Spinner} from 'react-bootstrap'

export default function LoadingScreen() {
    return <div className="startScreen" style={{ backgroundImage: `url(${background})` }}>
        <div style={{ marginBottom: "20px" }}/>
        <div className="startContent">
            <h1>Pelago</h1>
            <Spinner animation="border" variant="primary" />
        </div>
    </div>
}
