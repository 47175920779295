import './TopButtons.css'

export default function SpeedUpTimeScaleButton() {

    function increaseTimeScale() {
        global.world.increaseTimeScale()
    }

    return <div className="circleButton speedUpTimeScaleButton" onClick={increaseTimeScale}>
        <div>
            <span className="material-symbols-outlined buttonIcon">fast_forward</span>
        </div>
    </div>
}
