import { createSlice } from '@reduxjs/toolkit'

export const uiSlice = createSlice({
    name: 'ui',
    initialState: {
        fullscreenUiVisible: true,
        loadModalShown: false,
        saveModalShown: false,
        sideBarOpen: false,
        currentScreen: "start",
    },
    reducers: {
        showFullScreen: (state) => {
            state.fullscreenUiVisible = true
        },
        hideFullScreen: (state) => {
            state.fullscreenUiVisible = false
        },
        showSideBar: (state) => {
            state.sideBarOpen = true
        },
        toggleSideBar: (state) => {
            state.sideBarOpen = !state.sideBarOpen
        },
        hideSideBar: (state) => {
            state.sideBarOpen = false
        },
        navigateFullScreenTo: (state, data) => {
            state.currentScreen = data.payload
        },
        showSaveModal: (state) => {
            state.saveModalShown = true
        },
        hideSaveModal: (state) => {
            state.saveModalShown = false
        },
        showLoadModal: (state) => {
            state.loadModalShown = true
        },
        hideLoadModal: (state) => {
            state.loadModalShown = false
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    showFullScreen,
    hideFullScreen,
    showSideBar,
    toggleSideBar,
    hideSideBar,
    navigateFullScreenTo,
    showSaveModal,
    hideSaveModal,
    showLoadModal,
    hideLoadModal
} = uiSlice.actions

export default uiSlice.reducer
