import { Container, Graphics, Text } from 'pixi.js'

export default class TownLayer extends Container {

    townGraphics = []

    markerRadius = 50

    textStyle = {
        fontFamily: 'Libre Baskerville',
        fontSize: 200,
        fill: ['#ffffff', '#ffffff'], // gradient
        stroke: '#4a1850',
        strokeThickness: 1,
        dropShadow: true,
        dropShadowColor: '#000000',
        dropShadowBlur: 10,
        dropShadowAngle: Math.PI / 6,
        dropShadowDistance: 10,
        wordWrap: false
    }

    constructor() {
        super()
        this.drawTowns = this.drawTowns.bind(this)
        this.destroy = this.destroy.bind(this)
        this.freeTownGraphics = this.freeTownGraphics.bind(this)
        this.init = this.init.bind(this)
    }

    init() {
        this.drawTowns()
        return this
    }

    destroy(options) {
        this.freeTownGraphics()
        super.destroy(options)
    }

    freeTownGraphics() {
        this.townGraphics.forEach( gfx => {
            const text = gfx.children[0]
            text.destroy()
            gfx.destroy()
        })
        this.townGraphics.length = 0
    }

    drawTowns() {
        this.freeTownGraphics()

        const towns = global.world.currentRegion().towns
        towns.forEach( town => {
            const gfx = new Graphics()
            gfx.beginFill(0x000000)
            gfx.drawCircle(0, 0, this.markerRadius)
            gfx.endFill()
            gfx.beginFill(0xffffff)
            gfx.drawCircle(0, 0, this.markerRadius * 0.85)
            gfx.endFill()
            const townText = new Text(town.name, this.textStyle)
            const textX = -townText.width / 2
            const textY = -300
            gfx.addChild(townText)
            townText.position.set(textX, textY)
            gfx.town = town
            gfx.position.set(town.regionPosition.x, town.regionPosition.y)
            this.addChild(gfx)
            this.townGraphics.push(gfx)
        })
    }
}