import SkillSet from '../people/SkillSet'

export default function SkillsTable(props) {
    const skills = props.skills
    const skillDivs = SkillSet.skills.map( skill =>
        <div className="skillContainer" key={ skill.name }>
            <div className="skillGrid">
                <div className="skillName">{ skill.name }</div>
                <div className="skillValue">{ Math.floor(skills[skill.name]) }</div>
            </div>
        </div>
    )

    return <div className="skillsTable">
        { skillDivs }
    </div>
}
