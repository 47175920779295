export function capitalize(string) {
    if (!string) return ''
    const firstLetter = string.substring(0, 1)
    if (string.length > 1) {
        const remainingLetters = string.substring(1, string.length)
        return firstLetter.toUpperCase() + remainingLetters
    } else {
        return firstLetter.toUpperCase()
    }
}
