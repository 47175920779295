import SkillsTable from './SkillsTable'
import './PersonDetails.css'

export default function PersonDetails(props) {
    const person = props.person

    return <div className="personDetails">
        <div className="personDetailsLabel">Name:</div>
        <div className="personDetailsValue">{ person.firstName } { person.lastName }</div>
        <div className="personDetailsLabel">Gender:</div>
        <div className="personDetailsValue">{ person.gender }</div>
        <div className="personDetailsLabel">Fancies:</div>
        <div className="personDetailsValue">{ person.attraction }</div>
        <div className="personDetailsLabel">Age:</div>
        <div className="personDetailsValue">{ person.age() }</div>
        <div className="personDetailsLabel">Skills:</div>
        <div className="personDetailsValue"><SkillsTable skills={ person.skills } /></div>
    </div>
}
