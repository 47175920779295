import { Container, Graphics, Text } from 'pixi.js'

export default class TextScroller extends Container {

    static scrollSpeed = 20 / 60 // pixels/frame
    static linePause = 60 * 5 // measured in frames, equivalent to 5 seconds

    textStyle = {
        fontFamily: 'Libre Baskerville',
        fontSize: 16,
        //fontStyle: 'italic',
        //fontWeight: 'bold',
        fill: ['#f5f5dc', '#f5f5dc'], // gradient
        stroke: '#4a1850',
        strokeThickness: 1,
        dropShadow: true,
        dropShadowColor: '#000000',
        dropShadowBlur: 4,
        dropShadowAngle: Math.PI / 6,
        dropShadowDistance: 2,
        wordWrap: true,
        wordWrapWidth: 440,
        lineJoin: 'round',
    }

    texts = []
    nextTextY = 0

    constructor() {
        super()

        this.addText = this.addText.bind(this)
        this.destroy = this.destroy.bind(this)
        this.onResize = this.onResize.bind(this)
        this.redrawMask = this.redrawMask.bind(this)
        this.rerender = this.rerender.bind(this)
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.tick = this.tick.bind(this)
    }

    init() {
        this.scrollContainer = new Container()
        this.addChild(this.scrollContainer)

        this.maskGraphics = new Graphics()
        this.maskContainer = new Container()
        this.maskContainer.addChild(this.maskGraphics)
        this.mask = this.maskContainer
        this.addChild(this.maskContainer)

        this.pauseTimeRemaining = TextScroller.linePause

        return this
    }

    destroy(options) {
        this.stop()
        this.texts.forEach( text => {
            text.destroy()
        })
        this.texts.length = 0

        this.scrollContainer.destroy()
        this.scrollContainer = null

        this.maskGraphics.destroy()
        this.mask = null

        this.maskContainer.destroy()
        this.maskContainer = null

        super.destroy(options)
    }

    start() {
        global.pixiApp.app.ticker.add(this.tick)
    }

    stop() {
        global.pixiApp.app.ticker.remove(this.tick)
    }

    tick(delta) {
        // Case 0 -- we have no text
        if (this.texts.length === 0) return
        if (this.pauseTimeRemaining > 0) {
            // Case 1 -- we're waiting to scroll
            this.pauseTimeRemaining -= delta
        } else {
            // Case 2 -- we're scrolling
            const distance = TextScroller.scrollSpeed * delta
            this.scrollContainer.y -= distance
            const topText = this.texts[0]
            if (-this.scrollContainer.y > topText.y + topText.height) {
                // The top text scrolled out of view, time to remove it
                this.texts.shift()
                this.scrollContainer.removeChild(topText)
                topText.destroy()
                this.pauseTimeRemaining = TextScroller.linePause
                if (this.texts.length === 0) {
                    // No more texts, reset everything back to 0
                    this.nextTextY = 0
                    this.scrollContainer.y = 0
                    this.stop()
                }
            }
        }
    }

    addText(message) {
        if (this.texts.length === 0) {
            this.pauseTimeRemaining = TextScroller.linePause
            this.start()
        }
        const text = new Text(message, this.textStyle)
        this.texts.push(text)
        text.position.set(0, this.nextTextY)
        this.scrollContainer.addChild(text)
        this.nextTextY += text.height
    }

    redrawMask(width) {
        this.maskGraphics.clear()
        this.maskGraphics.beginFill(0xffffff)
        this.maskGraphics.drawRect(0, 0, width, 280)
        this.maskGraphics.endFill()
    }

    rerender() {
        this.texts = this.texts.map( text => {
            const newText = new Text(text.text, this.textStyle)
            text.destroy()
            return newText
        })
    }

    onResize(width, height) {
        this.position.set(30, height - 300)
        this.textStyle.wordWrapWidth = width * 2 / 3
        this.redrawMask(width, height)
        this.rerender()
    }
}