import Alea from 'aleaprng'

const rand = Alea()

export default class SkillSet {

    // TODO: Maybe these should be objects?
    static blacksmith = { name: "blacksmith", description: "Working with metal" }
    static cannon = { name: "cannon", description: "The big guns" }
    static carpentry = { name: "carpentry", description: "Working with wood" }
    static cooking = { name: "cooking", description: "Making delicious food" }
    static helm = { name: "helm", description: "Steering the ship" }
    static leadership = { name: "leadership", description: "Motivating the crew" }
    static melee = { name: "melee", description: "Fighting close up" }
    static rigging = { name: "rigging", description: "Trimming the sails" }
    static shooting = { name: "shooting", description: "Accuracy with rifles and pistols" }
    static social = { name: "social", description: "Winning friends and influencing people" }

    static skills = [
        SkillSet.blacksmith,
        SkillSet.cannon,
        SkillSet.carpentry,
        SkillSet.cooking,
        SkillSet.helm,
        SkillSet.leadership,
        SkillSet.melee,
        SkillSet.rigging,
        SkillSet.shooting,
        SkillSet.social,
    ]

    constructor() {
        this.loadFromSaveData = this.loadFromSaveData.bind(this)
        SkillSet.skills.forEach(skill => this[skill.name] = 0 )
    }

    loadFromSaveData(data) {
        Object.assign(this, data)
        return this
    }

    static randomSkillset(chunks, points) {
        const skills = new SkillSet()
        for (let x = 0; x < chunks; x++) {
            const amount = points / chunks
            const skill = SkillSet.randomSkill()
            skills[skill.name] += amount
        }
        return skills
    }

    static randomSkill() {
        const index = rand.range(SkillSet.skills.length - 1)
        return SkillSet.skills[index]
    }
}
