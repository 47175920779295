import './TopButtons.css'

export default function CancelTimeScaleButton() {

    function cancelTimeScale() {
        global.world.cancelTimeScale()
    }

    return <div className="circleButton cancelTimeScaleButton" onClick={cancelTimeScale}>
        <div>
            <span className="material-symbols-outlined buttonIcon">play_arrow</span>
        </div>
    </div>
}
