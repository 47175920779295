import { Graphics } from 'pixi.js'

export default class WindIndicator extends Graphics {
    constructor() {
        super()

        this.draw = this.draw.bind(this)
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.tick = this.tick.bind(this)
    }

    init() {
        this.draw()
        return this
    }

    destroy(options) {
        this.stop()
        super.destroy(options)
    }

    draw() {
        this.lineStyle(2, 0x0)
        this.beginFill(0xff0000)
        this.drawPolygon([-4, 20, -4, -20, 0, -24, 4, -20, 4, 20])
        this.endFill()
    }

    start() {
        global.pixiApp.app.ticker.add(this.tick)
    }

    stop() {
        global.pixiApp.app.ticker.remove(this.tick)
    }

    tick(/* delta */) {
        this.rotation = global.world.weather.windDirection
    }
}