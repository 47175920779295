import {Container, Graphics, Sprite, Text, Texture} from 'pixi.js'
import Region from '../world/Region'
import Colors from './Colors'

export default class RegionMap extends Container {

    textStyle = {
        fontFamily: 'Tangerine',
        fontSize: 40,
        fill: ['#584234', '#7a5b48'], // gradient
        stroke: '#584234',
        strokeThickness: 1,
        dropShadow: false,
        dropShadowColor: '#000000',
        dropShadowBlur: 10,
        dropShadowAngle: Math.PI / 6,
        dropShadowDistance: 10,
        wordWrap: false
    }

    constructor() {
        super()
        this.draw = this.draw.bind(this)
        this.drawRegionPolygons = this.drawRegionPolygons.bind(this)
        this.init = this.init.bind(this)
        this.onPointerDown = this.onPointerDown.bind(this)
        this.onPointerMove = this.onPointerMove.bind(this)
        this.onPointerUp = this.onPointerUp.bind(this)
        this.onResize = this.onResize.bind(this)
        this.updateVisibleAreaRect = this.updateVisibleAreaRect.bind(this)

        this.interactive = true
    }

    init(region) {
        this.region = region

        this.on('pointerdown', this.onPointerDown)
        this.on('pointermove', this.onPointerMove)
        this.on('pointerup', this.onPointerUp)
        this.on('pointerupoutside', this.onPointerUp)

        this.mapLayer = new Graphics()
        this.addChild(this.mapLayer)
        this.townLayer = new Graphics()
        this.addChild(this.townLayer)

        this.visibleAreaRect = new Graphics()
        this.mapLayer.addChild(this.visibleAreaRect)

        const roseSvg = "/compass-rose.svg"
        const roseTexture = Texture.from(roseSvg)
        this.compass = new Sprite(roseTexture)
        this.compass.scale.set(0.3, 0.3)
        this.compass.alpha = 0.4
        this.addChild(this.compass)

        this.nameText = new Text(this.region.name, this.textStyle)
        this.addChild(this.nameText)

        return this
    }

    destroy(options) {
        this.mapLayer.destroy()
        this.townLayer.destroy()
        this.visibleAreaRect.destroy()
        this.compass.destroy()
        this.nameText.destroy()

        super.destroy(options)
    }

    draw() {
        const scale = this.shortestDimension / Region.size

        this.mapLayer.scale.set(scale, scale)
        this.townLayer.scale.set(scale, scale)

        this.mapLayer.clear()

        this.mapLayer.lineStyle(400, 0x000000, 1)
        this.mapLayer.beginFill(Colors.mapBackground, 1)
        this.mapLayer.drawRect(0, 0, Region.size, Region.size)
        this.mapLayer.endFill()

        this.mapLayer.lineStyle(200, 0x000000, 0.15)
        for (let x = Region.size / 10; x < Region.size; x += Region.size / 10) {
            this.mapLayer.moveTo(x, 0)
            this.mapLayer.lineTo(x, Region.size)
        }
        for (let y = Region.size / 10; y < Region.size; y += Region.size / 10) {
            this.mapLayer.moveTo(0, y)
            this.mapLayer.lineTo(Region.size, y)
        }

        const townSize = 1000
        this.townLayer.clear()
        this.region.towns.forEach( town => {
            this.townLayer.beginFill(0x000000)
            this.townLayer.drawRect(town.regionPosition.x - townSize / 2, town.regionPosition.y - townSize / 2, townSize, townSize)
            this.townLayer.endFill()
        })

        this.compass.position.set(this.shortestDimension - 90, this.shortestDimension - 100)

        this.nameText.position.set(10, this.shortestDimension - 42)

        this.drawRegionPolygons(this.region.landPolygons)
    }

    drawRegionPolygons(polygons) {
        if (!polygons) return
        this.mapLayer.lineStyle(200, Colors.mapOutline)
        this.mapLayer.beginFill(Colors.mapLand)
        polygons.forEach( poly => {
            this.mapLayer.drawPolygon(poly)
        })
        this.mapLayer.endFill()
    }

    updateVisibleAreaRect(visibleRect) {
        this.visibleAreaRect.clear()
        this.visibleAreaRect.lineStyle(400, 0xffffff, 1)
        this.visibleAreaRect.drawRect(0, 0, visibleRect.width, visibleRect.height)
        this.visibleAreaRect.position.set(visibleRect.x, visibleRect.y)
    }

    onPointerDown(event) {
        this.dragging = true
        const location = event.data.getLocalPosition(this.mapLayer)
        global.pixiApp.stopFollowingBoat()
        global.pixiApp.viewport.animate({
             time: 300,
             position: { x: location.x, y: location.y }
        })
        if (global.pixiApp.viewport.scaled > 0.2) {
            global.pixiApp.viewport.animate({
                time: 300,
                scale: 0.2
            })
        }
    }

    onPointerMove(event) {
        if (this.dragging) {
            const location = event.data.getLocalPosition(this.mapLayer)
            //global.pixiApp.viewport.moveCenter(location.x, location.y)
            global.pixiApp.viewport.animate({
                time: 50,
                position: { x: location.x, y: location.y }
            })
        }
    }

    onPointerUp(event) {
        this.dragging = false
        const location = event.data.getLocalPosition(this.mapLayer)
        global.pixiApp.viewport.moveCenter(location.x, location.y)
    }

    onResize(width, height) {
        this.shortestDimension = Math.min(Math.min(width, height), 400)
        this.position.set(width - this.shortestDimension - 1, height - this.shortestDimension - 1)
        this.draw()
    }
}