import Alea from 'aleaprng'
import { nanoid } from 'nanoid'
import { capitalize } from '../utils/StringUtils'
import { randomElement } from '../utils/ArrayUtils'

const rand = Alea(nanoid())

export default class Town {

    name
    population

    regionPosition

    constructor() {
        this.init = this.init.bind(this)
        this.loadFromSaveData = this.loadFromSaveData.bind(this)
    }

    init() {
        this.name = Town.randomName()
        this.population = rand.range(40, 20000)
        return this
    }

    loadFromSaveData(data) {
        Object.assign(this, data)
        return this
    }

    static randomName() {
        let name = randomElement(Town.nameMiddles)
        if (rand.range(0, 9) === 0) {
            // No start letter.  Capitalize what we have already.
            name = capitalize(name)
        } else {
            name = randomElement(Town.nameStartLetters) + name
        }

        if (rand.range(1) === 0) {
            name = name + randomElement(Town.nameSuffixes)
        }

        return name
    }

    static namePrefixes = [
        "New ",
        "Port ",
        "San "
    ]

    static vowels = [
        "a", "e", "i", "o", "u", "y"
    ]

    static nameStartLetters = [
        "Ab",
        "Ac",
        "Ad",
        "Af",
        "Ag",
        "Al",
        "Am",
        "An",
        "Ap",
        "Ar",
        "As",
        "Ast",
        "At",
        "Az",
        "B",
        "B",
        "B",
        "B",
        "B",
        "B",
        "Bl",
        "Bl",
        "Br",
        "Br",
        "C",
        "C",
        "C",
        "C",
        "C",
        "C",
        "Ch",
        "Ch",
        "Cr",
        "Cr",
        "D",
        "D",
        "D",
        "D",
        "D",
        "D",
        "Dr",
        "Dr",
        "F",
        "F",
        "F",
        "F",
        "F",
        "F",
        "Fl",
        "Fl",
        "Fr",
        "Fr",
        "G",
        "G",
        "G",
        "G",
        "G",
        "G",
        "Gl",
        "Gl",
        "Gr",
        "Gr",
        "H",
        "H",
        "H",
        "H",
        "H",
        "H",
        "H",
        "H",
        "J",
        "J",
        "J",
        "J",
        "J",
        "J",
        "J",
        "J",
        "K",
        "K",
        "K",
        "K",
        "K",
        "K",
        "K",
        "Kl",
        "Kl",
        "Kr",
        "Kr",
        "Kw",
        "Kw",
        "L",
        "L",
        "L",
        "L",
        "L",
        "L",
        "L",
        "L",
        "M",
        "M",
        "M",
        "M",
        "M",
        "M",
        "M",
        "M",
        "N",
        "N",
        "N",
        "N",
        "N",
        "N",
        "N",
        "N",
        "P",
        "P",
        "P",
        "P",
        "P",
        "P",
        "P",
        "Pl",
        "Pl",
        "Pr",
        "Pr",
        "Qu",
        "Qu",
        "R",
        "R",
        "R",
        "R",
        "R",
        "R",
        "R",
        "R",
        "S",
        "S",
        "S",
        "S",
        "Sc",
        "Sh",
        "Shr",
        "Sl",
        "Sm",
        "Sn",
        "Sp",
        "St",
        "Sw",
        "T",
        "T",
        "T",
        "T",
        "T",
        "Th",
        "Tr",
        "Tw",
        "V",
        "V",
        "V",
        "V",
        "W",
        "W",
        "W",
        "W",
        "W",
        "W",
        "W",
        "W",
        "X",
        "Y",
        "Y",
        "Y",
        "Z"
    ]

    static nameMiddles = [
        "ackson",
        "alice",
        "allis",
        "and",
        "aple",
        "apel",
        "arbor",
        "arles",
        "arris",
        "arthur",
        "arwin",
        "avana",
        "avis",
        "eckson",
        "elice",
        "ellis",
        "elton",
        "en",
        "end",
        "ern",
        "erwin",
        "est",
        "etter",
        "evans",
        "idge",
        "iller",
        "it",
        "oder",
        "organ",
        "orge",
        "ot",
        "over",
        "un",
        "under",
        "urban"
    ]

    static nameSuffixes = [
        "berg",
        "burg",
        "cove",
        " Cove",
        "dale",
        "del",
        " Dell",
        "den",
        " Glen",
        " Harbor",
        "ion",
        "land",
        "many",
        "more",
        "on",
        " Park",
        "polis",
        "port",
        " Port",
        "son",
        "tania",
        "ton",
        "town",
        " Town",
        "vale",
        "vania",
        "ville",
        "way"
    ]
}
