import Alea from 'aleaprng'
import { nanoid } from 'nanoid'
import Ellipse from './Ellipse'
import Region from './Region'

const rand = Alea(nanoid())

export default class Archipelago {
    constructor() {
        this.init = this.init.bind(this)
        this.loadFromSaveData = this.loadFromSaveData.bind(this)
        this.generateEllipses = this.generateEllipses.bind(this)
    }

    init(params) {
        this.ellipses = this.generateEllipses(params)
        return this
    }

    loadFromSaveData(data) {
        this.ellipses = data.ellipses.map( ellipseData => new Ellipse().loadFromSaveData(ellipseData) )
        return this
    }

    generateEllipses(params) {
        // We allow regions to override any aspect of terrain creation by providing their own params and funcs to
        // control the final parameters for a given ellipse.  This allows them to make decisions by looking at
        // other archipelagos that have already been created in the region.
        const ellipses = []
        const chainLength = params.chainLength ? params.chainLength() : Archipelago.chainLengthFuncs.default()
        const startPosition = params.startPosition ? params.startPosition() : Archipelago.startPositionFuncs.random()
        let anchorX = startPosition.x
        let anchorY = startPosition.y
        let angle = params.startAngle ? params.startAngle() : Archipelago.startAngleFuncs.random()
        //console.log(`++ Making archipelago -- chain length ${chainLength} start anchor: ${anchorX},${anchorY} start angle: ${angle}`)
        for (let i = 1; i <= chainLength; i++) {
            if (anchorX > Region.size * 0.8 || anchorX < Region.size * 0.2 ||
                anchorY > Region.size * 0.8 || anchorY < Region.size * 0.2) break
            const size = params.size ? params.size(i, chainLength) : Archipelago.sizeFuncs.default(i, chainLength)
            let ellipse = new Ellipse(anchorX, anchorY, size.a, size.b, angle)
            //console.log(`+++ ${ellipse}`)
            ellipses.push(ellipse)

            const spacing = params.spacing ? params.spacing() : Archipelago.spacingFuncs.default()
            const xDiff = Math.cos(angle) * ellipse.a * spacing
            const yDiff = Math.sin(angle) * ellipse.a * spacing
            anchorX = ellipse.x + xDiff
            anchorY = ellipse.y + yDiff
            angle = (params.angle ? params.angle(angle) : Archipelago.angleFuncs.default(angle)) % (Math.PI * 2)
            //console.log(`+++ anchor: ${anchorX},${anchorY} angle: ${angle} spacing: ${spacing}`)
        }
        return ellipses
    }

    static chainLengthFuncs = {
        default: () => rand.range(1, 10),
        long: () => rand.range(5, 30)
    }

    static angleFuncs = {
        default: (lastAngle) => (lastAngle + rand.range(-0.7, 0.7))
    }

    static sizeFuncs = {
        default: () => {
            const a = rand.range(300, 3000)
            const b = rand.range(300, a * 0.6)
            return { a, b }
        },
        grow: (position, chainLength) => {
            const a = rand.range(1000, 5000)
            const b = rand.range(1000, a * 0.6)
            const multiplier = 2 * position / chainLength
            return { a: a * multiplier, b: b * multiplier }
        }
    }

    static spacingFuncs = {
        default: () => rand.range(0.5, 2),
        close: () => rand.range(0.2, 0.7)
    }

    static startPositionFuncs = {
        random: () => {
            return {
                x: rand.range(Region.size * 0.2, Region.size * 0.8),
                y: rand.range(Region.size * 0.2, Region.size * 0.8)
            }
        }
    }

    static startAngleFuncs = {
        random: () => rand.range(0.0, 2 * Math.PI)
    }

    static presets = {
        default: {},
        bigChain: {
            chainLength: Archipelago.chainLengthFuncs.long,
            size: Archipelago.sizeFuncs.grow,
            spacing: Archipelago.spacingFuncs.close
        }
    }
}
