import { Graphics } from 'pixi.js'
import Chunk from './Chunk'
import Region from '../world/Region'
import ChunkManager from './ChunkManager'

export default class Terrain extends Graphics {

    // The terrain object is the size of a region, but we only show an area that encompasses
    // the visible chunks.  This view of the terrain is managed by the viewport.
    static maxVisibleMeters = Chunk.size * (ChunkManager.chunksPerRow - 2)
    static minVisibleMeters = 100

    constructor() {
        super()

        this.addChunk = this.addChunk.bind(this)
        this.colliders = this.colliders.bind(this)
        this.destroy = this.destroy.bind(this)
        this.init = this.init.bind(this)
        this.onViewportZoomEnd = this.onViewportZoomEnd.bind(this)
        this.removeChunk = this.removeChunk.bind(this)
    }

    init() {
        global.pixiApp.viewport.on('zoomed-end', this.onViewportZoomEnd)

        this.grid = new Graphics()
        this.grid.lineStyle(25, 0x000000, 0.15)
        for (let x = Chunk.size; x < Region.size; x += Chunk.size) {
            this.grid.moveTo(x, 0)
            this.grid.lineTo(x, Region.size)
        }
        for (let y = Chunk.size; y < Region.size; y += Chunk.size) {
            this.grid.moveTo(0, y)
            this.grid.lineTo(Region.size, y)
        }
        this.addChild(this.grid)

        this.detailGrid = new Graphics()
        this.detailGrid.lineStyle(10, 0x000000, 0.15)
        for (let x = Chunk.size / 10; x < Region.size; x += Chunk.size / 10) {
            this.detailGrid.moveTo(x, 0)
            this.detailGrid.lineTo(x, Region.size)
        }
        for (let y = Chunk.size / 10; y < Region.size; y += Chunk.size / 10) {
            this.detailGrid.moveTo(0, y)
            this.detailGrid.lineTo(Region.size, y)
        }
        this.addChild(this.detailGrid)
        this.detailGrid.visible = false

        return this
    }

    colliders() {
        const colliders = []
        this.children.forEach( child => {
            if (child.colliders) {
                colliders.push(...child.colliders())
            }
        })
        return colliders
    }

    destroy(options) {
        global.pixiApp.viewport.off('zoomed-end', this.onViewportZoomEnd)
        // Any active chunks will be destroyed by ChunkManager before we get here
        this.grid.destroy()
        this.detailGrid.destroy()
        super.destroy(options)
    }

    addChunk(chunk) {
        this.addChild(chunk)
    }

    removeChunk(chunk) {
        this.removeChild(chunk)
    }

    onViewportZoomEnd(viewport) {
        this.detailGrid.visible = viewport.scaled > 0.5
    }
}
