import Alea from 'aleaprng'
import { createNoise2D } from 'simplex-noise'
import { randomElement } from '../utils/ArrayUtils'
import { nanoid } from 'nanoid'
import Archipelago from './Archipelago'
import Town from './Town'
import Chunk from '../pixi/Chunk'

const rand = Alea(nanoid())

export default class Region {

    static size = 100000 // square km
    minArchipelagos = 10
    maxArchipelagos = 10
    bigChainChance = 0.2
    falloffDistance = 2000 // meters
    falloffSteps = 6 // distance * steps == enforced shoreline slope distance

    noiseParams = {
        scale: 0.07,
        octaves: 3,
        persistence: 0.6,
        lacunarity: 2,
        octaveOffsets: [
            { x: 37337, y: 2112},
            { x: 98765, y: 12345 },
            { x: 65536, y: 86753 }
        ],
        noiseOffset: 0.6 // < 1 means height maps can go negative
    }

    constructor() {
        this.init = this.init.bind(this)
        this.loadFromSaveData = this.loadFromSaveData.bind(this)
        this.generateArchipelagos = this.generateArchipelagos.bind(this)
        this.generateTowns = this.generateTowns.bind(this)
        this.makeLandmassPolygons = this.makeLandmassPolygons.bind(this)
    }

    init(x, y, name) {
        this.worldId = global.world.id
        this.x = x
        this.y = y
        this.name = name
        this.noise = createNoise2D(Alea(`${global.world.id}:${x}:${y}`))
        this.archipelagos = this.generateArchipelagos()
        this.towns = this.generateTowns()
        this.landAabbPolygons = this.makeLandmassPolygons()
        return this
    }

    loadFromSaveData(data) {
        const worldId = data.worldId || global.world.id
        Object.assign(this, data)
        this.archipelagos = data.archipelagos.map( archData => new Archipelago().loadFromSaveData(archData) )
        this.towns = data.towns.map( townData => new Town().loadFromSaveData(townData) )
        this.noise = createNoise2D(Alea(`${worldId}:${this.x}:${this.y}`))
        return this
    }

    generateArchipelagos() {
        const numArchipelagos = rand.range(this.minArchipelagos, this.maxArchipelagos)
        console.log(`Creating ${numArchipelagos} archipelagos`)
        const archipelagos = []
        for (let i = 0; i < numArchipelagos; i++) {
            //console.log(`+ Archipelago`)
            const bigChain = rand.range(0.99) < this.bigChainChance
            const arch = new Archipelago().init(bigChain ? Archipelago.presets.bigChain : Archipelago.presets.default)
            archipelagos.push(arch)
        }
        //console.log(`Made ${numArchipelagos} archipelagos`)
        return archipelagos
    }

    generateTowns() {
        // Method 1: Random placement
        // const numTowns = rand.range(this.minTowns, this.maxTowns)
        // const towns = []
        // for (let i = 0; i < numTowns; i++) {
        //     const town = new Town().init()
        //     console.log(`+ Created town ${town.name}, population ${town.population}`)
        //     let location = null
        //     let tries = 0
        //     while (location === null) {
        //         tries++
        //         const candidateArch = randomElement(this.archipelagos)
        //         const candidateEllipse = randomElement(candidateArch.ellipses)
        //         const candidatePoint = candidateEllipse.randomEdgePoint()
        //         if (!this.pointIsInland(candidatePoint))
        //             location = candidatePoint
        //     }
        //     town.regionPosition = location
        //     console.log(`++ It took ${tries} tries to find a location: ${location.x},${location.y}`)
        //     towns.push(town)
        // }

        // Method 2: Placement by landmass size
        const towns = []
        this.archipelagos.forEach( arch => {
            let townCount = arch.ellipses.length * 0.2
            let totalArea = 0
            arch.ellipses.forEach( ellipse => totalArea += Math.PI * ellipse.a * ellipse.b )
            console.log(`+ chain length: ${arch.ellipses.length}  total area: ${totalArea}`)
            townCount += totalArea / 150000000 // 1 town per 150,000,000 km2
            townCount = Math.floor(townCount)
            console.log(`+ Town count for archipelago: ${townCount}`)
            for (let i = 0; i < townCount; i++) {
                let location = null
                let tries = 0
                while (tries < 10 && location === null) {
                    const candidateEllipse = randomElement(arch.ellipses)
                    const candidatePoint = candidateEllipse.randomEdgePoint()
                    if (!this.pointIsInland(candidatePoint))
                        location = candidatePoint
                }
                if (location != null) {
                    const town = new Town().init()
                    town.regionPosition = location
                    towns.push(town)
                    console.log(`++ Created town ${town.name}, population ${town.population} at ${location.x},${location.y}`)
                } else {
                    console.log(`++ Failed to find a location for town ${i} :(`)
                }
            }
        })

        return towns
    }

    pointIsInland(point) {
        for (let i = 0; i < this.archipelagos.length; i++) {
            const arch = this.archipelagos[i]
            for (let j = 0; j < arch.ellipses.length; j++) {
                const ellipse = arch.ellipses[j]
                if (ellipse.containsPoint(point.x, point.y, 1.0))
                    return true
            }
        }
        return false
    }

    makeLandmassPolygons() {
        // First Pass
        // Use chunk boundaries as edges.  If the AABB of any ellipse overlaps a chunk, that chunk is included in the
        // landmass.  This is very fast but the resolution of landmasses is very low, so we often miss passages between
        // ellipses that are close to one another.
        const polygons = []
        const consumed = []
        for (let y = 0; y < Region.size; y += Chunk.size) {
            for (let x = 0; x < Region.size; x += Chunk.size) {
                if (chunkContainsEllipses(this.archipelagos, x, y)) {
                    polygons.push(makePolygon(this.archipelagos, x, y))
                }
            }
        }
        return polygons

        function chunkContainsEllipses(archipelagos, x, y) {
            return archipelagos.map( arch => arch.ellipses ).flat().find( ellipse => ellipse.aabbCollides([x, y, x + Chunk.size, y + Chunk.size]))
        }

        function makePolygon(archipelagos, x, y) {
            consumed.push([x, y])
            const edgePoints = []
            const stack = []
            stack.push([4, [x, y]])
            stack.push([3, [x, y]])
            stack.push([2, [x, y]])
            stack.push([1, [x, y]])

            while(stack.length > 0) {
                const entry = stack.pop()
                const direction = entry[0]
                if (direction === 0) {
                    edgePoints.push(...entry[1]) // TODO: collinear points
                    continue
                }
                const prevCoords = entry[1]
                let newX, newY
                switch (direction) {
                    case 1: // left
                        newX = prevCoords[0] - Chunk.size
                        newY = prevCoords[1]
                        if (consumed.find( item => item[0] === newX && item[1] === newY )) {
                            // Already in this polygon, no need to add edges
                            continue
                        }
                        if (newX < 0) {
                            // Left edge of the region, close the polygon edge.
                            stack.push([0, [prevCoords[0], prevCoords[1]]])
                            continue
                        }
                        if (chunkContainsEllipses(archipelagos, newX, newY)) {
                            consumed.push([newX, newY])
                            stack.push([2, [newX, newY]])
                            stack.push([1, [newX, newY]])
                            stack.push([4, [newX, newY]])
                        } else {
                            // We found a left edge.  We should already have the lower-left coord, add the upper-left to close the edge.
                            stack.push([0, [prevCoords[0], prevCoords[1]]])
                        }
                        break
                    case 2: // up
                        newX = prevCoords[0]
                        newY = prevCoords[1] - Chunk.size
                        if (consumed.find( item => item[0] === newX && item[1] === newY )) {
                            // Already in this polygon, no need to add edges
                            continue
                        }
                        if (newY < 0) {
                            // Top edge of the region, close the polygon edge.
                            stack.push([0, [prevCoords[0] + Chunk.size, prevCoords[1]]])
                            continue
                        }
                        if (chunkContainsEllipses(archipelagos, newX, newY)) {
                            consumed.push([newX, newY])
                            stack.push([3, [newX, newY]])
                            stack.push([2, [newX, newY]])
                            stack.push([1, [newX, newY]])
                        } else {
                            // We found a top edge.  We should already have the upper-left coord, add the upper-right to close the edge.
                            stack.push([0, [prevCoords[0] + Chunk.size, prevCoords[1]]])
                        }
                        break
                    case 3: // right
                        newX = prevCoords[0] + Chunk.size
                        newY = prevCoords[1]
                        if (consumed.find( item => item[0] === newX && item[1] === newY )) {
                            // Already in this polygon, no need to add edges
                            continue
                        }
                        if (newX >= Region.size) {
                            // We hit the right edge of the region.  Close the edge.
                            stack.push([0, [newX, newY + Chunk.size]])
                            continue
                        }
                        if (chunkContainsEllipses(archipelagos, newX, newY)) {
                            consumed.push([newX, newY])
                            stack.push([4, [newX, newY]])
                            stack.push([3, [newX, newY]])
                            stack.push([2, [newX, newY]])
                        } else {
                            // We found a right edge.  We should already have the upper-right coord, add the lower-right to close the edge.
                            stack.push([0, [newX, newY + Chunk.size]])
                        }
                        break
                    case 4: // down
                        newX = prevCoords[0]
                        newY = prevCoords[1] + Chunk.size
                        if (consumed.find( item => item[0] === newX && item[1] === newY )) {
                            // Already in this polygon, no need to add edges
                            continue
                        }
                        if (newY >= Region.size) {
                            // We hit the bottom of the region.  Close the edge.
                            stack.push([0, [newX, newY]])
                            continue
                        }
                        if (chunkContainsEllipses(archipelagos, newX, newY)) {
                            consumed.push([newX, newY])
                            stack.push([1, [newX, newY]])
                            stack.push([4, [newX, newY]])
                            stack.push([3, [newX, newY]])
                        } else {
                            // We found a bottom edge.  We should already have the lower-right coord, add the lower-left to close the edge.
                            stack.push([0, [newX, newY]])
                        }
                        break
                    default:
                        throw new Error(`Invalid direction when building landmass polygon: ${direction}`)
                }
            }
            return edgePoints
        }

    }

    static regionPrefixes = [
        "A", "Ab", "Aba", "Abe", "Abi", "Abo", "Abu", "Ad", "Ada", "Ade", "Adi", "Ado", "Adu", "Af", "Afa", "Afe", "Afi", "Afo", "Afu", "Ag", "Aga", "Age", "Agi", "Ago", "Agu", "Agy", "Ak", "Aka", "Ake", "Aki", "Ako", "Aku", "Aky", "Al", "Ala", "Ale", "Ali", "Alo", "Alu", "Aly", "Am", "Ama", "Ame", "Ami", "Amo", "Amu", "Amy", "An", "Ana", "Ane", "Ani", "Ano", "Anu", "Any", "Ap", "Apa", "Ape", "Api", "Apo", "Apu", "Apy", "Ar", "Ara", "Are", "Ari", "Aro", "Aru", "Ary", "As", "Asa", "Ase", "Asi", "Aso", "Asu", "Asy", "At", "Ata", "Ate", "Ati", "Ato", "Atu", "Aty", "Av", "Ava", "Ave", "Avi", "Avo", "Avu", "Avy", "Awa", "Awe", "Awi", "Awo", "Awu", "Awy", "Aza", "Aze", "Azi", "Azo", "Azu", "Azy",
        "Ba", "Be", "Bi", "Bla", "Ble", "Bli", "Blo", "Blu", "Bly", "Bo", "Bra", "Bre", "Bri", "Bro", "Bru", "Bry", "Bu", "By", "Ba", "Be", "Bi", "Bla", "Ble", "Bli", "Blo", "Blu", "Bly", "Bo", "Bra", "Bre", "Bri", "Bro", "Bru", "Bry", "Bu", "By", "Ba", "Be", "Bi", "Bla", "Ble", "Bli", "Blo", "Blu", "Bly", "Bo", "Bra", "Bre", "Bri", "Bro", "Bru", "Bry", "Bu", "By", "Ba", "Be", "Bi", "Bla", "Ble", "Bli", "Blo", "Blu", "Bly", "Bo", "Bra", "Bre", "Bri", "Bro", "Bru", "Bry", "Bu", "By",
        "Ca", "Ce", "Cha", "Che", "Chi", "Cho", "Chu", "Ci", "Cla", "Cle", "Cli", "Clo", "Clu", "Cly", "Co", "Cra", "Cre", "Cri", "Cro", "Cru", "Cry", "Cu", "Cy", "Ca", "Ce", "Cha", "Che", "Chi", "Cho", "Chu", "Ci", "Cla", "Cle", "Cli", "Clo", "Clu", "Cly", "Co", "Cra", "Cre", "Cri", "Cro", "Cru", "Cry", "Cu", "Cy", "Ca", "Ce", "Cha", "Che", "Chi", "Cho", "Chu", "Ci", "Cla", "Cle", "Cli", "Clo", "Clu", "Cly", "Co", "Cra", "Cre", "Cri", "Cro", "Cru", "Cry", "Cu", "Cy",
        "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy", "Da", "De", "Di", "Do", "Du", "Dy",
        "E", "Ea", "Eb", "Eba", "Ebe", "Ebi", "Ebo", "Ebu", "Eby", "Ec", "Eca", "Ece", "Eci", "Eco", "Ecu", "Ecy", "Ed", "Eda", "Ede", "Edi", "Edo", "Edu", "Edy", "Ef", "Efa", "Efe", "Efi", "Efo", "Efu", "Efy", "Eg", "Ega", "Ege", "Egg", "Egi", "Ego", "Egu", "Egy", "Ei", "Ek", "Eka", "Eke", "Eki", "Eko", "Eku", "Eky", "El", "Ela", "Ele", "Eli", "Elo", "Elu", "Ely", "Em", "Ema", "Eme", "Emi", "Emo", "Emu", "Emy", "En", "Ena", "Ene", "Eni", "Eno", "Enu", "Eny", "Ep", "Epa", "Epe", "Epi", "Epo", "Epu", "Epy", "Er", "Era", "Ere", "Eri", "Ero", "Eru", "Ery", "Es", "Esa", "Ese", "Esi", "Eso", "Esu", "Esy", "Et", "Eta", "Ete", "Eti", "Eto", "Etu", "Ety", "Eu", "Ev", "Eva", "Eve", "Evi", "Evo", "Evu", "Evy", "Ex", "Exa", "Exe", "Exi", "Exo", "Exu", "Exy",
        "Fa", "Fe", "Fi", "Fla", "Fle", "Fli", "Flo", "Flu", "Fly", "Fo", "Fra", "Fre", "Fri", "Fro", "Fru", "Fry", "Fu", "Fa", "Fe", "Fi", "Fla", "Fle", "Fli", "Flo", "Flu", "Fly", "Fo", "Fra", "Fre", "Fri", "Fro", "Fru", "Fry", "Fu", "Fa", "Fe", "Fi", "Fla", "Fle", "Fli", "Flo", "Flu", "Fly", "Fo", "Fra", "Fre", "Fri", "Fro", "Fru", "Fry", "Fu", "Fa", "Fe", "Fi", "Fla", "Fle", "Fli", "Flo", "Flu", "Fly", "Fo", "Fra", "Fre", "Fri", "Fro", "Fru", "Fry", "Fu", "Fa", "Fe", "Fi", "Fla", "Fle", "Fli", "Flo", "Flu", "Fly", "Fo", "Fra", "Fre", "Fri", "Fro", "Fru", "Fry", "Fu",
        "Ga", "Ge", "Gha", "Ghe", "Ghi", "Gho", "Ghu", "Gi", "Gla", "Gle", "Gli", "Glo", "Glu", "Gly", "Go", "Gra", "Gre", "Gri", "Gro", "Gru", "Gry", "Gu", "Gwa", "Gwe", "Gwo", "Gwy", "Gy", "Ga", "Ge", "Gha", "Ghe", "Ghi", "Gho", "Ghu", "Gi", "Gla", "Gle", "Gli", "Glo", "Glu", "Gly", "Go", "Gra", "Gre", "Gri", "Gro", "Gru", "Gry", "Gu", "Gwa", "Gwe", "Gwo", "Gwy", "Gy", "Ga", "Ge", "Gha", "Ghe", "Ghi", "Gho", "Ghu", "Gi", "Gla", "Gle", "Gli", "Glo", "Glu", "Gly", "Go", "Gra", "Gre", "Gri", "Gro", "Gru", "Gry", "Gu", "Gwa", "Gwe", "Gwo", "Gwy", "Gy",
        "Ha", "He", "Hi", "Ho", "Hu", "Hy", "Ha", "He", "Hi", "Ho", "Hu", "Hy", "Ha", "He", "Hi", "Ho", "Hu", "Hy", "Ha", "He", "Hi", "Ho", "Hu", "Hy", "Ha", "He", "Hi", "Ho", "Hu", "Hy", "Ha", "He", "Hi", "Ho", "Hu", "Hy", "Ha", "He", "Hi", "Ho", "Hu", "Hy", "Ha", "He", "Hi", "Ho", "Hu", "Hy", "Ha", "He", "Hi", "Ho", "Hu", "Hy", "Ha", "He", "Hi", "Ho", "Hu", "Hy",
        "I", "Ib", "Id", "If", "Ig", "Ik", "Il", "Im", "In", "Io", "Ir", "Is", "It"
    ]
}
