import { DateTime } from 'luxon'
import { nanoid } from 'nanoid'
import Boat from '../boats/Boat'
import Person from '../people/Person'
import Region from './Region'
import Weather from './Weather'
import PixiApp from '../pixi/PixiApp'

export default class World {
    constructor() {
        this.addTickListener = this.addTickListener.bind(this)
        this.createStartRegion = this.createStartRegion.bind(this)
        this.currentRegion = this.currentRegion.bind(this)
        this.init = this.init.bind(this)
        this.loadFromSaveData = this.loadFromSaveData.bind(this)
        this.removeTickListener = this.removeTickListener.bind(this)
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.tick = this.tick.bind(this)

        this.tickListeners = new Set()
    }

    static loadSavedGame(slot) {
        if (global.world)
            global.world.stop()

        if (global.pixiApp)
            global.pixiApp.stop()

        global.world = new World().loadFromSaveData(JSON.parse(localStorage.getItem(slot)))

        global.pixiApp = new PixiApp()
        global.pixiApp.start()

        if (global.setReactPixiView)
            global.setReactPixiView(global.pixiApp.app.view)

        const coords = global.world.regionPosition
        console.log(`moving viewport to ${coords.x},${coords.y}`)
        global.pixiApp.viewport.moveCenter(coords.x, coords.y)
        global.pixiApp.onViewportMoved()
        global.pixiApp.log('Loaded saved game.')
    }

    init() {
        this.id = nanoid()
        this.regions = []
        this.currentRegionIndex = -1
        this.regionPosition = { x: -1, y: -1 }
        this.currentTime = 3155692597470 // 100 years from epoch 0
        this.timeScale = 1
        this.creationTime = DateTime.now()
        this.people = []
        this.weather = new Weather()
        this.boats = [ new Boat() ]
        return this
    }

    loadFromSaveData(data) {
        this.id = data.id
        this.regions = data.regions.map( regionData => new Region().loadFromSaveData(regionData) )
        this.currentRegionIndex = data.currentRegionIndex
        this.regionPosition = data.regionPosition
        this.currentTime = data.currentTime
        this.timeScale = 1
        this.creationTime = new DateTime(data.creationTime)
        this.people = data.people.map( personData => new Person().loadFromSaveData(personData) )
        this.weather = new Weather().loadFromSaveData(data.weather)
        this.boats = data.boats.map( boatData => new Boat().loadFromSaveData(boatData) )
        return this
    }

    addTickListener(listener) {
        this.tickListeners.add(listener)
    }

    cancelTimeScale() {
        this.timeScale = 1
    }

    createStartRegion() {
        console.log(`Creating start region`)
        this.regions = [ new Region().init(100, 100, "Startlandia") ]
        console.log(`region has ${this.regions[0].archipelagos.length} archipelagos`)
        this.currentRegionIndex = 0
        this.regionPosition = { x: Region.size / 2, y: Region.size / 2 }
    }

    currentRegion() {
        return this.regions[this.currentRegionIndex]
    }

    increaseTimeScale() {
        switch (this.timeScale) {
            case 1:
                this.timeScale = 2
                break
            case 2:
                this.timeScale = 5
                break
            case 5:
                this.timeScale = 10
                break
            case 10:
                this.timeScale = 60
                break
            default:
                this.timeScale = 60
        }
    }

    findPerson(id) {
        return this.people.find( person => person.id === id )
    }

    pauseTimeScale() {
        this.timeScale = 0
    }

    removeTickListener(listener) {
        this.tickListeners.delete(listener)
    }

    start() {
        global.pixiApp.app.ticker.add(this.tick)
        this.weather.start()
        this.boats.forEach( boat => boat.start() )
    }

    stop() {
        global.pixiApp.app.ticker.remove(this.tick)
        this.weather.stop()
        this.boats.forEach( boat => boat.stop() )
    }

    tick(delta) {
        if (this.timeScale === 0) return // No need to tick or update time
        delta *= this.timeScale
        this.currentTime += delta * 1000/60
        this.tickListeners.forEach( listener => listener.tick(delta) )
    }
}
