import Strategy from './Strategy'
import {angleDiff} from '../../utils/MathUtils'

export class LetSailsFlap extends Strategy {
    execute(delta, boat, person) {
        boat.trimAngle = boat.maxSailAngle
    }
}

export class PrettyGoodTrim extends Strategy {
    execute(delta, boat, person) {
        const inIrons = boat.trueWind.angle > (11 * Math.PI / 6) || boat.trueWind.angle < Math.PI / 6
        const upwind = boat.apparentWind.angle > 3 * Math.PI / 2 || boat.apparentWind.angle < Math.PI / 2
        let bestTrimAngle
        if (upwind) {
            // Maximize lift
            const diff = angleDiff(0, boat.apparentWind.angle) // Should be -Pi/2 .. Pi/2
            bestTrimAngle = Math.max(0, Math.abs(diff) - 0.4)
            //console.log(`upwind -- trueWind => ${boat.trueWind.angle}  diff => ${diff}  bestTrimAngle => ${bestTrimAngle} trimAngle => ${boat.trimAngle} sailAngle => ${boat.sailAngle} apparentWind => ${boat.apparentWind.angle}`)
        } else {
            // Maximize drag
            const diff = angleDiff(Math.PI, boat.apparentWind.angle) // Should be -Pi/2 .. Pi/2
            bestTrimAngle = Math.PI / 2 - Math.abs(diff)
            //console.log(`downwind -- bestTrimAngle => ${bestTrimAngle}`)
        }
        if (inIrons) {
            boat.trimAngle = boat.maxSailAngle // let them luff to minimize drag
        } else {
            boat.trimAngle = Math.max(0, bestTrimAngle)
        }
    }
}
