import {Graphics, Text} from 'pixi.js'
import Colors from './Colors'
import ConcavePolygon from './ConcavePolygon'

export default class Chunk extends Graphics {
    // Chunks are 1 square kilometer, with terrain resolution determined by our level of detail.
    // When LOD = 10, our chunks will be defined by a 10x10 height map, 10m squares
    // When LOD = 100, we would have 1m squares.
    // For rendering purposes, though, we need the height maps for the chunk to contain LOD + 1
    //  values in each dimension, so that we can make a full square at the borders.
    static size = 1000 // meters, but also the same as rendering units for our terrain map

    debug = false

    constructor(x, y, region, heightMap, lod) {
        super()
        this.position.set(x, y)
        this.region = region
        this.heightMap = heightMap
        this.lod = lod
        this.polygons = null
        this.collisionPolygons = []

        this.colliders = this.colliders.bind(this)
        this.colorForHeight = this.colorForHeight.bind(this)
        this.draw = this.draw.bind(this)
        this.heightAt = this.heightAt.bind(this)
        this.key = this.key.bind(this)
        this.setPolygons = this.setPolygons.bind(this)
    }

    colliders() {
        return this.collisionPolygons
    }

    colorForHeight(height) {
        if (height < 0.3)
            return Colors.beach
        else if (height < 0.6)
            return Colors.jungle
        else if (height < 1.0)
            return Colors.hills
        else if (height < 2.0)
            return Colors.mountains
        else
            return Colors.snow
    }

    draw() {
        const vertexDistance = Chunk.size / this.lod
        const shapes = new Uint8Array(this.lod * this.lod)
        let i = 0
        for (let y = 0; y < this.lod; y++) {
            for (let x = 0; x < this.lod; x++) {
                // Split each square into four triangles and render each one
                let renderablePoints = 0
                const v1Height = this.heightAt(x, y)
                if (v1Height > 0) renderablePoints++
                const v2Height = this.heightAt(x + 1, y)
                if (v2Height > 0) renderablePoints++
                const v3Height = this.heightAt(x + 1, y + 1)
                if (v3Height > 0) renderablePoints++
                const v4Height = this.heightAt(x, y + 1)
                if (v4Height > 0) renderablePoints++
                if (renderablePoints > 2) {
                    const v1X = x * vertexDistance
                    const v1Y = y * vertexDistance
                    const v2X = (x + 1) * vertexDistance
                    const v2Y = y * vertexDistance
                    const v3X = (x + 1) * vertexDistance
                    const v3Y = (y + 1) * vertexDistance
                    const v4X = x * vertexDistance
                    const v4Y = (y + 1) * vertexDistance
                    if (v1Height > 0 && v2Height > 0 && v3Height > 0) {
                        const avgHeight = (v1Height + v2Height + v3Height) / 3
                        this.beginFill(this.colorForHeight(avgHeight))
                        this.drawPolygon([v1X, v1Y, v2X, v2Y, v3X, v3Y])
                        this.endFill()
                        shapes[i] |= 1
                    }
                    if (v2Height > 0 && v3Height > 0 && v4Height > 0) {
                        const avgHeight = (v2Height + v3Height + v4Height) / 3
                        this.beginFill(this.colorForHeight(avgHeight))
                        this.drawPolygon([v2X, v2Y, v3X, v3Y, v4X, v4Y])
                        this.endFill()
                        shapes[i] |= 2
                    }
                    if (v3Height > 0 && v4Height > 0 && v1Height > 0) {
                        const avgHeight = (v3Height + v4Height + v1Height) / 3
                        this.beginFill(this.colorForHeight(avgHeight))
                        this.drawPolygon([v3X, v3Y, v4X, v4Y, v1X, v1Y])
                        this.endFill()
                        shapes[i] |= 4
                    }
                    if (v4Height > 0 && v1Height > 0 && v2Height > 0) {
                        const avgHeight = (v4Height + v1Height + v2Height) / 3
                        this.beginFill(this.colorForHeight(avgHeight))
                        this.drawPolygon([v4X, v4Y, v1X, v1Y, v2X, v2Y])
                        this.endFill()
                        shapes[i] |= 8
                    }
                }
                i++
            }
        }

        if (this.debug) {
            const textStyle = {
                fontFamily: 'sans-serif',
                fontSize: 40,
                fill: ['#ffffff', '#ffffff'], // gradient
                stroke: '#000000',
                strokeThickness: 1,
                dropShadow: true,
                dropShadowColor: '#000000',
                dropShadowBlur: 2,
                dropShadowAngle: Math.PI / 6,
                dropShadowDistance: 2,
                wordWrap: false
            }
            const keyText = new Text(this.key(), textStyle)
            this.addChild(keyText)
        }

        return shapes
    }

    drawPolygons() {
        this.lineStyle({ width: 5, color: 0x000000 })
        this.polygons.forEach( polygon => this.drawPolygon(polygon) )
    }

    heightAt(x, y) {
        return this.heightMap[x + y * (this.lod + 1)]
    }

    key() {
        return Chunk.keyForChunk(this.x, this.y)
    }

    setPolygons(polygons) {
        this.polygons = polygons
        this.collisionPolygons = polygons.map( polygon => new ConcavePolygon(this, polygon) )
        this.drawPolygons()
    }

    static keyForChunk(chunkX, chunkY) {
        return `{"x":${chunkX},"y":${chunkY}}`
    }
}
