import { Container, Text } from 'pixi.js'
import { formattedDate } from '../utils/DateUtils'

export default class DebugInfo extends Container {

    textStyle = {
        fontFamily: 'Libre Baskerville',
        fontSize: 16,
        fill: ['#f5f5dc', '#f5f5dc'], // gradient
        stroke: '#4a1850',
        strokeThickness: 1,
        dropShadow: true,
        dropShadowColor: '#000000',
        dropShadowBlur: 4,
        dropShadowAngle: Math.PI / 6,
        dropShadowDistance: 2,
        wordWrap: true,
        wordWrapWidth: 440,
        lineJoin: 'round',
    }

    constructor() {
        super()

        this.draw = this.draw.bind(this)
        this.tick = this.tick.bind(this)
        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
    }

    init() {
        this.draw()
        return this
    }

    destroy(options) {
        this.stop()
        this.fps.destroy()
        this.lod.destroy()
        this.windSpeed.destroy()
        this.sog.destroy()
        this.time.destroy()
        super.destroy(options)
    }

    draw() {
        this.fps = new Text('', this.textStyle)
        this.framesSinceLastUpdate = 0
        this.addChild(this.fps)

        this.lod = new Text("", this.textStyle)
        this.addChild(this.lod)
        this.lod.position.set(0, this.fps.y + this.fps.height)

        this.windSpeed = new Text("", this.textStyle)
        this.addChild(this.windSpeed)
        this.windSpeed.position.set(0, this.lod.y + this.lod.height)

        this.sog = new Text("", this.textStyle)
        this.addChild(this.sog)
        this.sog.position.set(0, this.windSpeed.y + this.windSpeed.height)

        this.time = new Text("", this.textStyle)
        this.addChild(this.time)
        this.time.position.set(0, this.sog.y + this.sog.height)
    }

    tick(delta) {
        this.framesSinceLastUpdate += delta
        if (this.framesSinceLastUpdate > 60) {
            this.fps.text = `FPS: ${Math.floor(global.pixiApp.app.ticker.FPS)}`
            this.lod.text = `LOD: ${global.pixiApp.chunkManager.currentLod}`
            this.windSpeed.text = `Wind: ${Math.round(global.world.weather.windSpeed * 3.6 / 1.852 * 10) / 10} knots`
            this.sog.text = `SOG: ${Math.round(global.world.boats[0].speedOverGround() * 3.6 / 1.852 * 10) / 10} knots`
            this.time.text = formattedDate(global.world.currentTime)
            this.framesSinceLastUpdate = 0
        }
    }

    start() {
        global.pixiApp.app.ticker.add(this.tick)
    }

    stop() {
        global.pixiApp.app.ticker.remove(this.tick)
    }
}