import {
    addMagnitudeToVector,
    addVector,
    angleDiff,
    magnitudeAndAngleFor, maxMagnitude,
    multiplyVectorByScalar,
    normalizeAngle, rotateVector,
    vectorFor
} from '../utils/MathUtils'
import Strategies from '../people/tasks/Strategies'

export default class Boat {

    position = { x: 50000, y: 50000 }
    destination = { x: 0, y: 0 }

    heading = 0        // radians
    rudderAngle = 0    // radians
    maxRudderAngle = 1 // radians
    rudderSpeed = 0.5  // Multiplier for rudder angle, should be lower for bigger boats

    sailAngle = 0              // radians, 0 == sails over the center stern
    trimAngle = 0              // radians, how far the rigging will allow the sail to swing
    maxSailAngle = Math.PI / 2 // radians, how far the boom can swing
    sailRotationSpeed = 600    // frames for a full rotation when wind is 1m/s
    sailReefLevel = 1          // 0 = No sails, 1 = full sails
    minSailDragForLift = 0.1   // magnitude of drag required to fill sails

    sailLiftMultiplier = 0.05 // m/s/frame when wind speed = 1
    sailDragMultiplier = 0.1  // m/s/frame when wind speed = 1

    targetSpeed = 5    // meters / sec
    maxSpeed = 7       // meters / sec
    boatDrag = 0.4
    aground = false
    movementVector = { x: 0, y: 0 } // meters / sec
    movement = { angle: 0, magnitude: 0 }

    crew = []
    tasks = [
        { name: 'rigging', priority: 0, doer: null },
        { name: 'helm', priority: 0, doer: null }
    ]

    trueWind = { angle: 0, magnitude: 0 }
    apparentWind = { angle: 0, magnitude: 0 } // trueWind + movement

    // Force physical model debugging.  When set these will be shown as force indicators.
    sailLift = { angle: 0, magnitude: 0 }
    sailDrag = { angle: 0, magnitude: 0 }
    keelForce = { angle: 0, magnitude: 0 }

    constructor() {
        this.steer = this.steer.bind(this)
        this.adjustMovement = this.adjustMovement.bind(this)
        this.adjustSails = this.adjustSails.bind(this)
        this.loadFromSaveData = this.loadFromSaveData.bind(this)
        this.move = this.move.bind(this)
        this.personAssignedTo = this.personAssignedTo.bind(this)
        this.start = this.start.bind(this)
        this.steer = this.steer.bind(this)
        this.stop = this.stop.bind(this)
        this.taskNamed = this.taskNamed.bind(this)
        this.tick = this.tick.bind(this)
        this.trimSails = this.trimSails.bind(this)
    }

    init() {
        return this
    }

    loadFromSaveData(data) {
        Object.assign(this, data)
        return this
    }

    adjustMovement(delta) {

        // Drag is strongest when the angle of attack is Pi/2.  We use a simple linear model to calculate drag force here.
        const sailAttack = angleDiff(this.sailAngle, this.apparentWind.angle) // -Pi .. 0 .. Pi
        let absSailAttack = Math.abs(sailAttack) // 0 .. Pi
        if (absSailAttack > Math.PI / 2) absSailAttack = Math.PI - absSailAttack // sailAttack is now 0 .. Pi/2
        const sailDrag = (absSailAttack / (Math.PI / 2)) * this.sailDragMultiplier * this.apparentWind.magnitude * this.sailReefLevel
        const sailDragVector = vectorFor(normalizeAngle(this.apparentWind.angle + Math.PI), sailDrag)
        this.sailDrag = magnitudeAndAngleFor(sailDragVector)

        // Lift is only generated if the sails are filled.  That means the sail needs we need a minimal amount of drag,
        // configurable as minSailDragForLift.  If this is satisfied we use the angle of attack to compute the lift.
        // There is a fairly narrow slice of the angle of attack that will produce lift before stalling out and making
        // drag the dominant component, configurable as sailAttackForStall.
        let sailLiftVector
        let lift = 0
        if (sailDrag >= this.minSailDragForLift) {
            const baseLift = Math.pow((Math.PI / 2 - absSailAttack) / (Math.PI / 2), 2) // 0 .. 1, with exponential curve up
            lift = baseLift * this.apparentWind.magnitude * this.sailReefLevel
            let sailLiftAngle
            if (sailAttack >= 0)
                sailLiftAngle = normalizeAngle(this.sailAngle - Math.PI / 2)
            else
                sailLiftAngle = this.sailAngle + Math.PI / 2
            sailLiftVector = vectorFor(sailLiftAngle, lift)
        } else {
            // Not enough wind/attack to fill the sails
            sailLiftVector = { x: 0, y: 0 }
        }
        this.sailLift = magnitudeAndAngleFor(sailLiftVector)
        const totalSailVector = addVector(sailLiftVector, sailDragVector)
        const totalSail = magnitudeAndAngleFor(totalSailVector)

        const keelMagnitude = totalSail.magnitude
        const keelForceAngle = normalizeAngle(-totalSail.angle)
        this.keelForce = { angle: keelForceAngle, magnitude: keelMagnitude }
        const keelForceVector = vectorFor(this.keelForce.angle, this.keelForce.magnitude)
        const dragMultiplier = this.aground ? 5000 : this.boatDrag
        const hullDrag = Math.pow(this.movement.magnitude / this.maxSpeed, 2) * dragMultiplier
        // TODO: mast / rudder drag

        this.movementVector = addVector(this.movementVector, multiplyVectorByScalar(sailLiftVector, 1/60*delta))
        this.movementVector = addVector(this.movementVector, multiplyVectorByScalar(sailDragVector, 1/60*delta))
        this.movementVector = addVector(this.movementVector, multiplyVectorByScalar(keelForceVector, 1/60*delta))
        this.movementVector = addMagnitudeToVector(this.movementVector, -hullDrag / (60*delta))
        this.movementVector = maxMagnitude(this.movementVector, Math.min(this.maxSpeed, this.trueWind.magnitude))
        this.movement = magnitudeAndAngleFor(this.movementVector)
    }

    adjustSails(delta) {
        // Establish the new wind data
        const trueWindDirection = normalizeAngle(global.world.weather.windDirection - this.heading)
        const trueWindSpeed = global.world.weather.windSpeed
        this.trueWindVector = vectorFor(trueWindDirection, trueWindSpeed)
        this.trueWind = { angle: trueWindDirection, magnitude: trueWindSpeed }
        this.apparentWindVector = addVector(this.trueWindVector, this.movementVector)
        this.apparentWind = magnitudeAndAngleFor(this.apparentWindVector)
        this.tack = this.apparentWindVector.x < 0 ? 'port' : 'starboard'

        // The sails are blown in the direction of the wind until they hit the angle set by the rigger (in either
        // direction) or the boom reaches its limit (maxSailAngle).
        // Normally the sail angle will be between 3Pi/2 and Pi (top half of the circle).  If it is outside those angles
        // something has gone terribly wrong with the rigging.

        // The trimAngle is a positive offset from sailAngle 0, and represents where the rigging is set.  This value is
        // symmetrical in that it will act as a limit in either direction.  The active direction depends on the current
        // wind conditions.
        const portTrimAngle = Math.max(this.trimAngle === 0 ? 2 * Math.PI : normalizeAngle(-this.trimAngle), normalizeAngle(-this.maxSailAngle))
        const starboardTrimAngle = Math.min(this.trimAngle, this.maxSailAngle)

        // Which way does the wind want to rotate the sails?  It will be the towards the direction of wind (since
        // in our model wind direction is where the wind is coming *from*).
        // A positive sailAngleDiff means that the wind wants to push the sail clockwise.  Negative will push it
        // counterclockwise.
        const sailAngleDiff = angleDiff(this.sailAngle, this.apparentWind.angle)
        if (sailAngleDiff) {
            // TODO: Prevent flapping
            const step = (sailAngleDiff < 0 ? -this.apparentWind.magnitude : this.apparentWind.magnitude) / this.sailRotationSpeed * delta
            let newSailAngle = normalizeAngle(this.sailAngle + step)
            if (newSailAngle >= Math.PI && newSailAngle < portTrimAngle) this.sailAngle = portTrimAngle
            else if (newSailAngle < Math.PI && newSailAngle > starboardTrimAngle) this.sailAngle = starboardTrimAngle
            else this.sailAngle = newSailAngle
        }
    }

    assignPersonTo(person, task) {
        task = this.taskNamed(task)
        if (task)
            task.doer = person.id
    }

    handleCollision(target) {
        console.log(`BOOM`)
    }

    move(delta) {
        const rotatedVector = rotateVector(this.movementVector, this.heading)
        this.position = addVector(this.position, multiplyVectorByScalar(rotatedVector, delta / 60))
    }

    personAssignedTo(taskName) {
        const task = this.tasks.find( task => task.name === taskName && task.doer !== null )
        return task ? global.world.findPerson(task.doer) : null
    }

    /**
     * How fast is the boat moving over ground, in meters per second.
     * @returns {number}
     */
    speedOverGround() {
        return this.movement.magnitude
    }

    start() {
        global.world.addTickListener(this)
    }

    steer(delta) {
        const task = this.taskNamed('helm')
        if (!task) return // We don't need to steer right now
        const helmsman = this.personAssignedTo('helm')
        let strategy
        if (helmsman)
            strategy = helmsman.bestStrategyFor(task)
        else
            strategy = Strategies.helm.noSteering

        strategy.execute(delta, this, helmsman)

        this.heading = normalizeAngle(this.heading + this.rudderAngle * this.rudderSpeed / 60 * delta)
    }

    stop() {
        global.world.removeTickListener(this)
    }

    taskNamed(name) {
        return this.tasks.find( task => task.name === name )
    }

    tick(delta) {
        // delta 1 = 1 / 60 seconds
        this.adjustSails(delta)
        this.trimSails(delta)
        this.steer(delta)
        this.adjustMovement(delta)
        this.move(delta)
    }

    trimSails(delta) {

        const task = this.taskNamed('rigging')
        if (!task) return // We don't need to trim right now
        const rigger = this.personAssignedTo('rigging')
        let strategy
        if (rigger)
            strategy = rigger.bestStrategyFor(task)
        else
            strategy = Strategies.rigging.letSailsFlap

        strategy.execute(delta, this, rigger)
    }
}
