import Alea from 'aleaprng'
import {nanoid} from 'nanoid'
import {clamp, normalizeAngle} from '../utils/MathUtils'

const rand = Alea(nanoid())

export default class Weather {
    windDirection = 0 // radians
    windSpeed = 4 // meters / sec
    frameCount = 0

    constructor() {
        this.loadFromSaveData = this.loadFromSaveData.bind(this)
        this.tick = this.tick.bind(this)
    }

    loadFromSaveData(data) {
        Object.assign(this, data)
        return this
    }

    start() {
        global.world.addTickListener(this)
    }

    stop() {
        global.world.removeTickListener(this)
    }

    tick(delta) {
        this.frameCount += delta
        if (this.frameCount > 60) {
            this.windDirection = normalizeAngle(this.windDirection + (rand.range(1) ? Math.PI / 64 : -Math.PI / 64) * delta)
            this.windSpeed = clamp(this.windSpeed + (rand.range(1) ? 0.1 : -0.1), 0, 8)
            this.frameCount = 0
        }
    }
}
