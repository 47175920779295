import './TopButtons.css'

export default function BoatButton() {

    function toggleFollowBoat() {
        global.pixiApp.toggleFollowBoat()
    }

    return <div className="circleButton boatButton" onClick={toggleFollowBoat}>
        <div>
            <span className="material-symbols-outlined buttonIcon">sailing</span>
        </div>
    </div>
}
